import i18next from 'i18next';
import { lazy } from 'react';
import en from './i18n/en';
import es from './i18n/es';
import UserView from './users/UsersSidebar/UsersSidebarDetails';
import UserViewPermissions from './users/UsersSidebar/UsersSidebarPermissions';
import TeamView from './teams/TeamsSidebar/TeamSidebarContent';
import RoleView from './roles/RolesSidebar/RolesSidebarContent';
import ProjectView from './projects/ProjectsSidebarContent';
import TagView from './tags/TagsSidebarContent';
import ClientView from './clients/rightSidebar/ClientsSidebarContent';
import AlertView from './alerts/AlertsSidebarContent';
import CsvLoadView from './cvsLoad/rightSidebar/CsvLoadSidebarContent';
import NotificationView from './notifications/rightSidebar/NotificationSidebarContent';
import EventView from './events/rightSidebar/EventsSidebarContent';
import ApplicationView from './applications/rightSidebar/ApplicationsSidebarContent';

i18next.addResourceBundle('en', 'managementApp', en);
i18next.addResourceBundle('es', 'managementApp', es);

const Projects = lazy(() => import('./projects/ProjectsApp'));
const Alerts = lazy(() => import('./alerts/AlertsApp'));
const Tags = lazy(() => import('./tags/TagsApp'));
const Clients = lazy(() => import('./clients/ClientsApp'));
const Users = lazy(() => import('./users/UsersApp'));
const Roles = lazy(() => import('./roles/RolesApp'));
const Teams = lazy(() => import('./teams/TeamsApp'));
const CvsLoad = lazy(() => import('./cvsLoad/CsvLoadApp'));
const NotificationLoad = lazy(() => import('./notifications/NotificationsApp'));
const EventLoad = lazy(() => import('./events/EventsApp'));
const ApplicationLoad = lazy(() => import('./applications/ApplicationsApp'));
const SyncHistoryLoad = lazy(() => import('./syncHistory/SyncHistoryApp'));

const ManagementAppConfig = {
  settings: {
    layout: {},
  },

  routes: [
    {
      path: '/ws/:wsId/users/',
      element: <Users />,
      permissionsRequired: ['view_users_all'],
      children: [
        {
          path: ':userId/',
          element: <UserView />,
        },
        {
          path: 'permissions/:userId/',
          element: <UserViewPermissions />,
        },
      ],
    },
    {
      path: '/ws/:wsId/teams',
      element: <Teams />,
      permissionsRequired: ['view_teams_all'],
      children: [
        {
          path: ':teamId',
          element: <TeamView />,
        },
      ],
    },
    {
      path: '/ws/:wsId/roles',
      element: <Roles />,
      superUserRequired: true,
      children: [
        {
          path: ':roleId',
          element: <RoleView />,
        },
      ],
    },
    {
      path: '/ws/:wsId/projects',
      element: <Projects />,
      permissionsRequired: ['view_projects_all'],
      children: [
        {
          path: ':projectId',
          element: <ProjectView />,
        },
      ],
    },
    {
      path: '/ws/:wsId/tags',
      element: <Tags />,
      permissionsRequired: ['view_tags_all'],
      children: [
        {
          path: ':tagId',
          element: <TagView />,
        },
      ],
    },
    {
      path: '/ws/:wsId/alerts',
      element: <Alerts />,
      permissionsRequired: ['view_alert'],
      children: [
        {
          path: ':alertId',
          element: <AlertView />,
        },
      ],
    },
    {
      path: '/ws/:wsId/clients',
      element: <Clients />,
      permissionsRequired: ['view_clients_all'],
      children: [
        {
          path: ':clientId',
          element: <ClientView />,
        },
      ],
    },
    {
      path: '/ws/:wsId/csv-load',
      element: <CvsLoad />,
      superUserRequired: true,
      children: [
        {
          path: ':csvLoadId',
          element: <CsvLoadView />,
        },
      ],
    },
    {
      path: '/ws/:wsId/notifications',
      element: <NotificationLoad />,
      children: [
        {
          path: ':notificationId',
          element: <NotificationView />,
        },
      ],
    },
    {
      path: '/ws/:wsId/events',
      element: <EventLoad />,
      children: [
        {
          path: ':eventId',
          element: <EventView />,
        },
      ],
    },
    {
      path: '/ws/:wsId/applications',
      element: <ApplicationLoad />,
      children: [
        {
          path: ':applicationId',
          element: <ApplicationView />,
        },
      ],
    },
    {
      path: '/ws/:wsId/sync_history',
      element: <SyncHistoryLoad />,
    },
  ],
};

export default ManagementAppConfig;
