/** this file will contain the translations in english for the authApp */
const locale = {
  ALREADY_EXIST_ACCOUNT: 'There is already an account created with this email',
  ALREADY_HAVE_ACCOUNT: 'Already have an account?',
  CREATE_ACCOUNT: 'Create account',
  CHANGE_PASSWORD: 'Change password',
  DONT_HAVE_ACCOUNT: "Don't have an account?",
  GO_TO: 'Go to',
  INVALID_TOKEN: 'Invalid or expired token',
  INVALID_USER_PASSWORD: 'Invalid username or password',
  INVALID_LINK: 'The link is invalid',
  ENTER_YOUR_EMAIL_ADDRESS:
    'Enter your email address and we will send you a link to reset your password',
  ENTER_NEW_PASSWORD: 'Enter a new password, for user {{name}} {{last_name}} ({{email}})',
  EXPIRED_LINK: 'The link has expired',
  DO_YOU_FORGET_PASSWORD: 'Did you forget your password?',
  LAST_NAME: 'Last name',
  LOG_IN: 'Sign in',
  MUST_ENTER_EMAIL: 'You must enter an email',
  MUST_ENTER_VALID_EMAIL: 'You must enter a valid email',
  NAME: 'Name',
  NEW_PASSWORD_SUCCESSFULLY: 'Your password has been successfully reset',
  PASSWORD: 'Password',
  PASSWORD_CONFIRMATION: 'Password confirmation',
  PASSWORDS_MUST_MATCH: 'Passwords must match',
  PLEASE_ENTER_PASSWORD: 'Please enter your password',
  SEND_EMAIL: 'Send email',
  SERVER_ERROR: 'Cannot communicate with server',
  SIGN_IN: 'Sign in',
  SIGN_UP: 'Sign up',
  SIGN_UP_FAILED: 'Sign up failed',
  SUCCESSFUL_SIGN_UP: 'Successful sign up',
  THIS_IS_REQUIRED: 'This is a required field',
  RESTORE_PASSWORD: 'Restore password',
  WE_ARE_TEAM: 'We are part of your team',
  WE_ARE_TOOL: 'We are the most flexible tool on the market to manage your value chain',
  WE_HAVE_SEND_EMAIL: 'We have sent you an email to confirm your account.',
  WE_HAVE_SEND_EMAIL_TO_RESTORE_PASSWORD:
    'We have sent you an email to {{email}} to reset your password',
};

export default locale;
