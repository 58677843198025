import * as yup from 'yup';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import GeofenceChip from 'app/store/selectsData/Components/GeofenceChip';
import Fab from '@mui/material/Fab';
import FormControlLabel from '@mui/material/FormControlLabel';
import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';
import TeamChip from 'app/store/selectsData/Components/TeamChip';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import UserChip from 'app/store/selectsData/Components/UserChip';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { Controller, useForm } from 'react-hook-form';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { getTeamsAll, selectTeams } from 'app/store/selectsData/teamsSlice';
import { getUsersAll, selectUsers } from 'app/store/selectsData/usersSlice';
import { motion } from 'framer-motion';
import { permissionChecker } from 'app/store/userWorkspacePermissionsSlice';
import { roleChecker } from 'app/store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { getGeofencesAll, selectGeofences } from 'app/store/selectsData/geofenceSlice';

import { addAlert, selectAlertById, setAlert, setDeleteModalProps } from '../store/alertsSlice';

/**
 * default values for the form
 */
const defaultValues = {
  name: '',
  type: '',
  active: true,
  apply_all_users: true,
  users: [],
  teams: [],
  geofences: [],
  rules: {},
  platform_notification: false,
  email_notification: false,
  emails_to_notify: [],
};

const labelStyle = (theme) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  borderRadius: '15px',
  textAlign: 'center',
  paddingX: 1,
  paddingY: 0.25,
  marginX: 1,
  marginY: 0.25,
  maxWidth: 'min-content',
  minWidth: 'max-content',
  width: '100%',
  height: 1,
});

/**
 * User Chip
 * @param {object} props
 * @param {object} props.user - user object
 * @returns {JSX.Element}
 */
const UserLabel = ({ user }) => {
  return (
    <Typography variant="caption" className="font-medium" sx={labelStyle}>
      {`${user?.name}(${user?.email})`}
    </Typography>
  );
};

/**
 * Team Chip
 * @param {object} props
 * @param {object} props.team - team object
 */
const TeamLabel = ({ team }) => {
  return (
    <Typography variant="caption" className="font-medium" sx={labelStyle}>
      {team?.name}
    </Typography>
  );
};

function AlertsSidebarContent() {
  /**
   * @constant {object} wsId - workspace id
   * @constant {object} alertId - alert id
   * @constant {function} dispatch - the dispatch function from the redux store
   * @constant {boolean} editMode - if the form is in edit mode
   * @constant {boolean} isLoading - if the form is loading
   * @constant {object} alert - alert object
   * @constant {function} checkPermission - function to check permission of the user
   * @constant {function} checkRole - function to check role of the user
   * @constant {function} dispatch - the dispatch function from the redux store
   * @constant {function} navigate - the navigate function from the react-router-dom
   * @constant {ref} scrollRef - ref to scroll to the form
   * @constant {array} teams - teams array
   * @constant {array} users - users array
   * @constant {function} t - translation function
   */
  const { wsId, alertId } = useParams();
  const [editMode, setEditMode] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const alert = useSelector((state) => selectAlertById(state, alertId));
  const checkPermission = useSelector(permissionChecker);
  const checkRole = useSelector(roleChecker);
  const dispatch = useDispatch();
  const geofences = useSelector(selectGeofences);
  const navigate = useNavigate();
  const scrollRef = useRef(null);
  const teams = useSelector(selectTeams);
  const users = useSelector(selectUsers);
  const { t } = useTranslation('managementApp');

  /**
   * close the sidebar
   */
  const onClose = () => navigate('..');

  /**
   * activate the edit mode
   */
  const onEdit = async () => {
    if (alert) reset(alert);
    setEditMode(true);

    setLoading(true);
    await dispatch(getUsersAll(wsId));
    await dispatch(getTeamsAll(wsId));
    if (alert?.type === 'geo' || alertId === 'newGEO') await dispatch(getGeofencesAll(wsId));
    setLoading(false);
  };

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    name: yup.string().required(t('NAME_CANNOT_EMPTY')),
    rules: yup.object({
      max_speed: yup.number().typeError(t('MXS_CANNOT_EMPTY')).min(1, t('MXS_VALID')),
    }),
  });

  /**
   * methods for the form
   */
  const {
    control,
    formState,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    reset,
    watch,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  /** save the alert */
  const saveAlert = () => dispatch(setAlert({ wsId, alert: getValues() }));

  /** create the alert */
  const createAlert = async () => {
    const {
      payload: [{ id }],
    } = await dispatch(addAlert({ wsId, alert: getValues() }));
    navigate(`../${id}${window.location.search}`, { replace: true });
  };
  const { isValid, errors } = formState;

  /** open the modal to confirm delete the alert */
  const openDeleteModal = () => dispatch(setDeleteModalProps({ open: true, alertId }));

  /** handle the change of time when the alert is active */
  const handleChangeTime = (time) => {
    setValue('rules.hour', `0${time.getUTCHours()}`.slice(-2));
    setValue('rules.minute', `0${time.getUTCMinutes()}`.slice(-2));
  };

  /** handle the change of day when the alert is active */
  const handleChangeDay = (day) => {
    const days = getValues('rules.day_of_week')
      .split(',')
      .filter((e) => e !== '')
      .map((num) => parseInt(num, 10));

    if (days.includes(day)) {
      const index = days.indexOf(day);
      days.splice(index, 1);
    } else {
      days.push(day);
      days.sort((a, b) => a - b);
    }
    setValue('rules.day_of_week', days.join());
  };
  /** effect to set the alert in the form */
  useEffect(() => {
    if (alertId === 'newITS' || alertId === 'newMXS' || alertId === 'newGEO') {
      // setEditMode(true);
      onEdit();

      switch (alertId) {
        case 'newITS':
          reset({
            ...defaultValues,
            type: 'its',
            rules: { hour: '06', minute: '03', day_of_week: '' },
          });
          break;
        case 'newMXS':
          reset({
            ...defaultValues,
            type: 'mxs',
            rules: { max_speed: 100 },
          });
          break;
        case 'newGEO':
          reset({
            ...defaultValues,
            type: 'geo',
            rules: { foo: 'aaa' },
          });
          break;
        default:
          break;
      }
    } else {
      setEditMode(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert, alertId]);

  /** effect to scroll to the form when the edit mode is activated */
  useEffect(() => scrollRef?.current?.scrollIntoView(), [editMode]);

  if (!alert && !(alertId === 'newITS' || alertId === 'newMXS' || alertId === 'newGEO')) {
    return null;
  }

  if (isLoading) {
    return (
      <div className="h-full flex-1 flex items-center" ref={scrollRef}>
        <FuseLoading />
      </div>
    );
  }

  return (
    <motion.div
      initial={{ y: 50, opacity: 0.8 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.3 } }}
      className="file-details px-24 "
      style={{ flex: '1 1 auto', height: '0px' }}
      ref={scrollRef}
    >
      <div className="flex items-center justify-end w-full ">
        <IconButton className="" size="medium" onClick={onClose}>
          <FuseSvgIcon>heroicons-solid:x</FuseSvgIcon>
        </IconButton>
      </div>
      {editMode || alert === undefined ? (
        <div>
          <form
            name="alertForm"
            noValidate
            className="flex flex-col justify-center w-full"
            onSubmit={handleSubmit((_data) => console.info(_data))}
          >
            <div className="flex flex-col my-16">
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="alert-name"
                    disabled={!checkPermission('edit_alert')}
                    label={t('NAME')}
                    autoFocus
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            </div>
            {!(alertId === 'newITS' || alertId === 'newMXS' || alertId === 'newGEO') && (
              <div className="flex flex-col my-16">
                <div>
                  <Typography variant="caption" className="font-medium">
                    {t('STATUS')}:
                  </Typography>
                  {watch('active') ? (
                    <Typography variant="caption" color="green">
                      {t('ON')}
                    </Typography>
                  ) : (
                    <Typography variant="caption" color="red">
                      {t('OFF')}
                    </Typography>
                  )}
                </div>
                <Controller
                  name="active"
                  type="checkbox"
                  control={control}
                  render={({ field: { onChange, value, ref, onBlur } }) => (
                    <Switch
                      disabled={!checkPermission('edit_alert')}
                      checked={value}
                      onBlur={onBlur}
                      onChange={(ev) => onChange(ev.target.checked)}
                      inputRef={ref}
                    />
                  )}
                />
              </div>
            )}
            {getValues('type') === 'mxs' && (
              <div className="flex flex-col items-start w-full">
                <div className="flex items-center gap-5">
                  <Typography variant="caption" className="font-medium" display="inline">
                    {t('TYPE')}:
                  </Typography>
                  <Typography variant="caption">{t('MXS')}</Typography>
                  <FuseSvgIcon className="text-48" size={18} color="action">
                    material-solid:speed
                  </FuseSvgIcon>
                </div>
                <div className="flex flex-wrap items-center my-16 w-full">
                  <Typography variant="caption" className="font-medium" display="inline">
                    {t('MXS')}:
                  </Typography>
                  <Controller
                    name="rules.max_speed"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        disabled={!checkPermission('edit_alert')}
                        type="number"
                        size="small"
                        error={!!errors?.rules?.max_speed}
                        helperText={errors?.rules?.max_speed?.message}
                        variant="outlined"
                        className="w-1/2 sm:w-1/3  m-1"
                        InputProps={{
                          endAdornment: <InputAdornment position="start">km/h</InputAdornment>,
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            )}
            {getValues('type') === 'its' && (
              <div className="flex flex-col items-start">
                <div className="flex items-center gap-5">
                  <Typography variant="caption" className="font-medium" display="inline">
                    {t('TYPE')}:
                  </Typography>
                  <Typography variant="caption">{t('ITS')}</Typography>
                  <FuseSvgIcon className="text-48" size={18} color="action">
                    material-solid:notifications_paused
                  </FuseSvgIcon>
                </div>
                <div className="flex flex-wrap items-center my-16 w-full">
                  <Typography variant="caption" className="font-medium" display="inline">
                    {t('MAX_WITHOUT_ACTIVITY')}:
                  </Typography>
                  <TimePicker
                    value={
                      new Date(
                        `0001-01-01T${watch('rules')?.hour}:${watch('rules')?.minute}:00.000Z`
                      )
                    }
                    disabled={!checkPermission('edit_alert')}
                    onChange={handleChangeTime}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="w-1/2  m-1"
                        inputProps={{ ...params.inputProps, readOnly: true }}
                        size="small"
                      />
                    )}
                  />
                </div>
                <Typography variant="caption" className="font-medium" display="inline">
                  {t('DAYS_ALERT_APPLIES')}:
                </Typography>
                <div className="w-full flex py-16" style={{ gap: '2.5px' }}>
                  <Box
                    sx={(theme) => ({
                      backgroundColor: watch('rules').day_of_week?.includes('0')
                        ? theme.palette.secondary.main
                        : '#CCC',
                      flex: 1,
                      border: 'solid',
                      borderColor: '#000',
                      borderWidth: checkPermission('edit_alert') ? '1px' : 0,
                      borderRadius: '15px',
                      textAlign: 'center',
                      cursor: 'pointer',
                      ':hover': {
                        backgroundColor: watch('rules').day_of_week?.includes('0')
                          ? theme.palette.secondary.dark
                          : '#AAA',
                      },
                    })}
                    onClick={() => (checkPermission('edit_alert') ? handleChangeDay(0) : {})}
                  >
                    <Typography variant="caption" color="#FFF">
                      {format(new Date(0, 0, 1), 'EEE')}
                    </Typography>
                  </Box>
                  <Box
                    sx={(theme) => ({
                      backgroundColor: watch('rules').day_of_week?.includes('1')
                        ? theme.palette.secondary.main
                        : '#CCC',
                      flex: 1,
                      border: 'solid',
                      borderColor: '#000',
                      borderWidth: checkPermission('edit_alert') ? '1px' : 0,
                      borderRadius: '15px',
                      textAlign: 'center',
                      cursor: 'pointer',
                      ':hover': {
                        backgroundColor: watch('rules').day_of_week?.includes('1')
                          ? theme.palette.secondary.dark
                          : '#AAA',
                      },
                    })}
                    onClick={() => (checkPermission('edit_alert') ? handleChangeDay(1) : {})}
                  >
                    <Typography variant="caption" color="#FFF">
                      {format(new Date(0, 0, 2), 'EEE')}
                    </Typography>
                  </Box>
                  <Box
                    sx={(theme) => ({
                      backgroundColor: watch('rules').day_of_week?.includes('2')
                        ? theme.palette.secondary.main
                        : '#CCC',
                      flex: 1,
                      border: 'solid',
                      borderColor: '#000',
                      borderWidth: checkPermission('edit_alert') ? '1px' : 0,
                      borderRadius: '15px',
                      textAlign: 'center',
                      cursor: 'pointer',
                      ':hover': {
                        backgroundColor: watch('rules').day_of_week?.includes('2')
                          ? theme.palette.secondary.dark
                          : '#AAA',
                      },
                    })}
                    onClick={() => (checkPermission('edit_alert') ? handleChangeDay(2) : {})}
                  >
                    <Typography variant="caption" color="#FFF">
                      {format(new Date(0, 0, 3), 'EEE')}
                    </Typography>
                  </Box>
                  <Box
                    sx={(theme) => ({
                      backgroundColor: watch('rules').day_of_week?.includes('3')
                        ? theme.palette.secondary.main
                        : '#CCC',
                      flex: 1,
                      border: 'solid',
                      borderColor: '#000',
                      borderWidth: checkPermission('edit_alert') ? '1px' : 0,
                      borderRadius: '15px',
                      textAlign: 'center',
                      cursor: 'pointer',
                      ':hover': {
                        backgroundColor: watch('rules').day_of_week?.includes('3')
                          ? theme.palette.secondary.dark
                          : '#AAA',
                      },
                    })}
                    onClick={() => (checkPermission('edit_alert') ? handleChangeDay(3) : {})}
                  >
                    <Typography variant="caption" color="#FFF">
                      {format(new Date(0, 0, 4), 'EEE')}
                    </Typography>
                  </Box>
                  <Box
                    sx={(theme) => ({
                      backgroundColor: watch('rules').day_of_week?.includes('4')
                        ? theme.palette.secondary.main
                        : '#CCC',
                      flex: 1,
                      border: 'solid',
                      borderColor: '#000',
                      borderWidth: checkPermission('edit_alert') ? '1px' : 0,
                      borderRadius: '15px',
                      textAlign: 'center',
                      cursor: 'pointer',
                      ':hover': {
                        backgroundColor: watch('rules').day_of_week?.includes('4')
                          ? theme.palette.secondary.dark
                          : '#AAA',
                      },
                    })}
                    onClick={() => (checkPermission('edit_alert') ? handleChangeDay(4) : {})}
                  >
                    <Typography variant="caption" color="#FFF">
                      {format(new Date(0, 0, 5), 'EEE')}
                    </Typography>
                  </Box>
                  <Box
                    sx={(theme) => ({
                      backgroundColor: watch('rules').day_of_week?.includes('5')
                        ? theme.palette.secondary.main
                        : '#CCC',
                      flex: 1,
                      border: 'solid',
                      borderColor: '#000',
                      borderWidth: checkPermission('edit_alert') ? '1px' : 0,
                      borderRadius: '15px',
                      textAlign: 'center',
                      cursor: 'pointer',
                      ':hover': {
                        backgroundColor: watch('rules').day_of_week?.includes('5')
                          ? theme.palette.secondary.dark
                          : '#AAA',
                      },
                    })}
                    onClick={() => (checkPermission('edit_alert') ? handleChangeDay(5) : {})}
                  >
                    <Typography variant="caption" color="#FFF">
                      {format(new Date(0, 0, 6), 'EEE')}
                    </Typography>
                  </Box>
                  <Box
                    sx={(theme) => ({
                      backgroundColor: watch('rules').day_of_week?.includes('6')
                        ? theme.palette.secondary.main
                        : '#CCC',
                      flex: 1,
                      border: 'solid',
                      borderColor: '#000',
                      borderWidth: checkPermission('edit_alert') ? '1px' : 0,
                      borderRadius: '15px',
                      textAlign: 'center',
                      cursor: 'pointer',
                      ':hover': {
                        backgroundColor: watch('rules').day_of_week?.includes('6')
                          ? theme.palette.secondary.dark
                          : '#AAA',
                      },
                    })}
                    onClick={() => (checkPermission('edit_alert') ? handleChangeDay(6) : {})}
                  >
                    <Typography variant="caption" color="#FFF">
                      {format(new Date(0, 0, 7), 'EEE')}
                    </Typography>
                  </Box>
                </div>
              </div>
            )}
            {getValues('type') === 'geo' && (
              <div className="mp-16">
                <Typography variant="caption" className="font-medium">
                  {t('GEOFENCES_ALERT_APPLIES')}:
                </Typography>
                <Controller
                  name="geofences"
                  control={control}
                  render={({ field: { onChange, value, onBlur, ref } }) => (
                    <Autocomplete
                      className="mt-8 mb-16"
                      multiple
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          paddingRight: '10px!important',
                        },
                      }}
                      openOnFocus
                      // autoSelect
                      disableClearable
                      disabled={!checkPermission('edit_alert')}
                      popupIcon={false}
                      noOptionsText={t('NO_OPTION')}
                      options={geofences}
                      value={value}
                      isOptionEqualToValue={(option, geofenceID) => option.id === geofenceID}
                      getOptionLabel={(option) => option.name}
                      renderTags={(geofenceID, getTagProps) =>
                        geofenceID.map((id, index) => (
                          <GeofenceChip id={id} chipProps={getTagProps({ index })} key={id} />
                        ))
                      }
                      onChange={(e, newValue) =>
                        onChange(
                          newValue.map((geofenceID) => {
                            if (typeof geofenceID === 'string' || geofenceID instanceof String) {
                              return geofenceID;
                            }
                            return geofenceID.id;
                          })
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onBlur={onBlur}
                          inputRef={ref}
                        />
                      )}
                    />
                  )}
                />
              </div>
            )}
            <div className="my-16">
              <Controller
                name="apply_all_users"
                type="checkbox"
                control={control}
                render={({ field: { onChange, value, onBlur, ref } }) => (
                  <FormControlLabel
                    label={t('ALERT_FOR_ALL')}
                    control={
                      <Checkbox
                        disabled={!checkPermission('edit_alert')}
                        checked={value}
                        onBlur={onBlur}
                        onChange={(ev) => onChange(ev.target.checked)}
                        inputRef={ref}
                      />
                    }
                  />
                )}
              />
            </div>
            {!watch('apply_all_users') && (
              <>
                <div className="mp-16">
                  <Typography variant="caption" className="font-medium">
                    {t('ALERT_APPLIES_TO_USERS')}:
                  </Typography>
                  <Controller
                    name="users"
                    control={control}
                    render={({ field: { onChange, value, onBlur, ref } }) => (
                      <Autocomplete
                        className="mt-8 mb-16"
                        multiple
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            paddingRight: '10px!important',
                          },
                        }}
                        openOnFocus
                        // autoSelect
                        disableClearable
                        disabled={!checkPermission('edit_alert')}
                        popupIcon={false}
                        noOptionsText={t('NO_OPTION')}
                        options={users}
                        value={value}
                        isOptionEqualToValue={(option, userID) => option.id === userID}
                        getOptionLabel={(option) => `${option.name}(${option.email})`}
                        renderTags={(usersID, getTagProps) =>
                          usersID.map((id, index) => (
                            <UserChip id={id} chipProps={getTagProps({ index })} key={id} />
                          ))
                        }
                        onChange={(e, newValue) =>
                          onChange(
                            newValue.map((userID) => {
                              if (typeof userID === 'string' || userID instanceof String) {
                                return userID;
                              }
                              return userID.id;
                            })
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onBlur={onBlur}
                            inputRef={ref}
                          />
                        )}
                      />
                    )}
                  />
                </div>
                <div className="mp-16">
                  <Typography variant="caption" className="font-medium">
                    {t('ALERT_APPLIES_TO_TEAMS')}:
                  </Typography>
                  <Controller
                    name="teams"
                    control={control}
                    render={({ field: { onChange, value, onBlur, ref } }) => (
                      <Autocomplete
                        className="mt-8 mb-16"
                        multiple
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            paddingRight: '10px!important',
                          },
                        }}
                        openOnFocus
                        // autoSelect
                        disableClearable
                        popupIcon={false}
                        noOptionsText={t('NO_OPTION')}
                        options={teams}
                        value={value}
                        disabled={!checkPermission('edit_alert')}
                        isOptionEqualToValue={(option, teamID) => option.id === teamID}
                        getOptionLabel={(option) => option.name}
                        renderOption={(propsOption, option) => (
                          <Box {...propsOption} component="li" key={option.id}>
                            {option.name}
                          </Box>
                        )}
                        renderTags={(teamsID, getTagProps) =>
                          teamsID.map((id, index) => (
                            <TeamChip id={id} chipProps={getTagProps({ index })} key={id} />
                          ))
                        }
                        onChange={(rules, newValue) =>
                          onChange(
                            newValue.map((teamID) => {
                              if (typeof teamID === 'string' || teamID instanceof String) {
                                return teamID;
                              }
                              return teamID.id;
                            })
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            disabled={!checkPermission('edit_alert')}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onBlur={onBlur}
                            inputRef={ref}
                          />
                        )}
                      />
                    )}
                  />
                </div>
              </>
            )}
            <div className="my-16">
              <Controller
                name="email_notification"
                type="checkbox"
                control={control}
                render={({ field: { onChange, value, onBlur, ref } }) => (
                  <FormControlLabel
                    label={t('NOTIFY_EMAIL')}
                    control={
                      <Checkbox
                        disabled={!checkPermission('edit_alert')}
                        checked={value}
                        onBlur={onBlur}
                        onChange={(ev) => onChange(ev.target.checked)}
                        inputRef={ref}
                      />
                    }
                  />
                )}
              />
            </div>
            {watch('email_notification') && (
              <div className="mp-16">
                <Typography variant="caption" className="font-medium">
                  {t('NOTIFICATION_BE_SEND_EMAILS')}:
                </Typography>
                <Controller
                  name="emails_to_notify"
                  control={control}
                  render={({ field: { onChange, value, onBlur, ref } }) => (
                    <Autocomplete
                      className="mt-8 mb-16"
                      multiple
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          paddingRight: '10px!important',
                        },
                      }}
                      openOnFocus
                      freeSolo
                      // autoSelect
                      disableClearable
                      popupIcon={false}
                      noOptionsText={t('NO_OPTION')}
                      options={users.map((user) => user.email)}
                      value={value}
                      disabled={!checkPermission('edit_alert')}
                      renderTags={(Emails, getTagProps) =>
                        Emails.map((email, index) => (
                          <Chip
                            key={email}
                            sx={(theme) => ({
                              backgroundColor: theme.palette.secondary.main,
                              color: theme.palette.secondary.contrastText,
                              fontSize: 12,
                              '& .MuiChip-deleteIcon': {
                                color: theme.palette.secondary.contrastText,
                                fontSize: 15,
                              },
                            })}
                            variant="outlined"
                            label={email}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      onChange={(rules, newValue) => {
                        clearErrors('emails');
                        onChange(
                          newValue.filter((email) => {
                            const isEmail = String(email)
                              .toLowerCase()
                              .match(
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                              );
                            if (!isEmail) {
                              setError('emails', { type: 'custom', message: t('EMAIL_VALID') });
                            }
                            return isEmail;
                          })
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          disabled={!checkPermission('edit_alert')}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={!!errors.emails}
                          helperText={errors?.emails?.message}
                          onBlur={onBlur}
                          inputRef={ref}
                        />
                      )}
                    />
                  )}
                />
              </div>
            )}
            <div className="my-16">
              <Controller
                name="platform_notification"
                type="checkbox"
                control={control}
                render={({ field: { onChange, value, onBlur, ref } }) => (
                  <FormControlLabel
                    label={t('NOTIFY_PLATFORM')}
                    control={
                      <Checkbox
                        disabled={!checkPermission('edit_alert')}
                        checked={value}
                        onBlur={onBlur}
                        onChange={(ev) => onChange(ev.target.checked)}
                        inputRef={ref}
                      />
                    }
                  />
                )}
              />
            </div>
          </form>
          <div className="side-bar-float-buttons">
            {(alertId === 'newITS' || alertId === 'newMXS' || alertId === 'newGEO') &&
            checkPermission('create_alert') ? (
              <Tooltip title={t('CREATE')} placement="top">
                <Fab
                  color="secondary"
                  size="small"
                  aria-label="edit"
                  onClick={createAlert}
                  disabled={!isValid}
                >
                  <FuseSvgIcon size={20}>material-solid:save</FuseSvgIcon>
                </Fab>
              </Tooltip>
            ) : (
              <>
                {checkPermission('edit_alert') && (
                  <Tooltip title={t('SAVE')} placement="top">
                    <Fab
                      color="secondary"
                      size="small"
                      aria-label="edit"
                      onClick={saveAlert}
                      disabled={!isValid}
                    >
                      <FuseSvgIcon size={20}>material-solid:save</FuseSvgIcon>
                    </Fab>
                  </Tooltip>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="pb-8">
          <Typography className="text-20 font-500">{alert?.name}</Typography>
          <div className="w-full py-4">
            <Divider />
          </div>
          <div>
            <Typography variant="caption" className="pb-0 font-500" color="gray">
              {t('STATUS')}
            </Typography>
            <div className="border-2 border-gray-300 rounded-md p-2">
              {alert.active ? (
                <div className="flex gap-5">
                  <FuseSvgIcon className="text-48" size={24} color="success">
                    heroicons-solid:check-circle
                  </FuseSvgIcon>
                  <Typography className="font-500" variant="body1" color="green">
                    {t('ON')}
                  </Typography>
                </div>
              ) : (
                <div className="flex gap-5">
                  <FuseSvgIcon className="text-48" size={24} color="error">
                    heroicons-solid:x-circle
                  </FuseSvgIcon>
                  <Typography className="font-500" variant="body1" color="red">
                    {t('OFF')}
                  </Typography>
                </div>
              )}
            </div>
          </div>

          <div className="w-full py-4">
            <Divider />
          </div>

          <div>
            <Typography variant="caption" className="pb-0 font-500" color="gray">
              {t('TYPE')}
            </Typography>
            <div className="border-2 border-gray-300 rounded-md p-2">
              {alert.type === 'mxs' && (
                <div className="flex gap-5">
                  <FuseSvgIcon className="text-48" size={24}>
                    material-solid:speed
                  </FuseSvgIcon>
                  <Typography className="font-500" variant="body1">
                    {t('MXS')}
                  </Typography>
                </div>
              )}
              {alert.type === 'its' && (
                <div className="flex gap-5">
                  <FuseSvgIcon className="text-48" size={24}>
                    material-solid:notifications_paused
                  </FuseSvgIcon>
                  <Typography className="font-500" variant="body1">
                    {t('ITS')}
                  </Typography>
                </div>
              )}
              {alert.type === 'geo' && (
                <div className="flex gap-5">
                  <FuseSvgIcon className="text-48" size={24}>
                    material-solid:location_searching
                  </FuseSvgIcon>
                  <Typography className="font-500" variant="body1">
                    {t('GEOFENCE')}
                  </Typography>
                </div>
              )}
            </div>
          </div>

          <div>
            <Typography variant="caption" className="pb-0 font-500" color="gray">
              {t('RULE')}
            </Typography>
            <div className="border-2 border-gray-300 rounded-md p-2">
              {alert.type === 'mxs' && (
                <div className="flex gap-5">
                  <Typography className="font-500" variant="body1">
                    {t('MXS_RULE')}
                    {alert.rules.max_speed} km/h
                  </Typography>
                </div>
              )}
              {alert.type === 'its' && (
                <div className="flex flex-col gap-5">
                  <div>
                    <Typography className="font-500" variant="caption">
                      {t('ITS_RULE')}
                      {format(
                        new Date(`0001-01-01T${alert.rules?.hour}:${alert.rules?.minute}:00.000Z`),
                        'hh:mm aaaa '
                      )}
                    </Typography>
                  </div>

                  <div>
                    <Typography variant="caption" className="font-medium" display="inline">
                      {t('DAYS_ALERT_APPLIES')}:
                    </Typography>
                    <div className="w-full flex" style={{ gap: '2.5px' }}>
                      <Box
                        sx={(theme) => ({
                          backgroundColor: alert.rules.day_of_week?.includes('0')
                            ? theme.palette.secondary.main
                            : '#CCC',
                          flex: 1,
                          borderRadius: '15px',
                          textAlign: 'center',
                        })}
                      >
                        <Typography variant="caption" color="#FFF">
                          {format(new Date(0, 0, 1), 'EEE')}
                        </Typography>
                      </Box>
                      <Box
                        sx={(theme) => ({
                          backgroundColor: alert.rules.day_of_week?.includes('1')
                            ? theme.palette.secondary.main
                            : '#CCC',
                          flex: 1,
                          borderRadius: '15px',
                          textAlign: 'center',
                        })}
                      >
                        <Typography variant="caption" color="#FFF">
                          {format(new Date(0, 0, 2), 'EEE')}
                        </Typography>
                      </Box>
                      <Box
                        sx={(theme) => ({
                          backgroundColor: alert.rules.day_of_week?.includes('2')
                            ? theme.palette.secondary.main
                            : '#CCC',
                          flex: 1,
                          borderRadius: '15px',
                          textAlign: 'center',
                        })}
                      >
                        <Typography variant="caption" color="#FFF">
                          {format(new Date(0, 0, 3), 'EEE')}
                        </Typography>
                      </Box>
                      <Box
                        sx={(theme) => ({
                          backgroundColor: alert.rules.day_of_week?.includes('3')
                            ? theme.palette.secondary.main
                            : '#CCC',
                          flex: 1,
                          borderRadius: '15px',
                          textAlign: 'center',
                        })}
                      >
                        <Typography variant="caption" color="#FFF">
                          {format(new Date(0, 0, 4), 'EEE')}
                        </Typography>
                      </Box>
                      <Box
                        sx={(theme) => ({
                          backgroundColor: alert.rules.day_of_week?.includes('4')
                            ? theme.palette.secondary.main
                            : '#CCC',
                          flex: 1,
                          borderRadius: '15px',
                          textAlign: 'center',
                        })}
                      >
                        <Typography variant="caption" color="#FFF">
                          {format(new Date(0, 0, 5), 'EEE')}
                        </Typography>
                      </Box>
                      <Box
                        sx={(theme) => ({
                          backgroundColor: alert.rules.day_of_week?.includes('5')
                            ? theme.palette.secondary.main
                            : '#CCC',
                          flex: 1,
                          borderRadius: '15px',
                          textAlign: 'center',
                        })}
                      >
                        <Typography variant="caption" color="#FFF">
                          {format(new Date(0, 0, 6), 'EEE')}
                        </Typography>
                      </Box>
                      <Box
                        sx={(theme) => ({
                          backgroundColor: alert.rules.day_of_week?.includes('6')
                            ? theme.palette.secondary.main
                            : '#CCC',
                          flex: 1,
                          borderRadius: '15px',
                          textAlign: 'center',
                        })}
                      >
                        <Typography variant="caption" color="#FFF">
                          {format(new Date(0, 0, 7), 'EEE')}
                        </Typography>
                      </Box>
                    </div>
                  </div>
                </div>
              )}
              {alert.type === 'geo' && (
                <div className="flex flex-col gap-5">
                  <Typography className="font-500" variant="body1">
                    {t('GEOFENCE_RULE')}
                  </Typography>
                  <Typography variant="caption" className="font-medium" display="inline">
                    {t('GEOFENCES_ALERT_APPLIES')}:
                  </Typography>
                  <div className="w-full flex gap-2 flex-wrap">
                    {alert.geofences_data.map((geofence) => (
                      <div className="w-full flex items-center border-2 !border-black rounded-xl gap-5 px-4 py-2">
                        <FuseSvgIcon className="text-48" size={20}>
                          material-solid:location_searching
                        </FuseSvgIcon>
                        <div>
                          <Typography variant="body1" className="font-bold">
                            {geofence.name}
                          </Typography>
                          <Typography variant="caption">{geofence.description}</Typography>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="w-full py-4">
            <Divider />
          </div>
          <div className="border-2 border-gray-300 rounded-md p-2">
            {alert.apply_all_users ? (
              <Box
                className="w-full flex items-center border-2 !border-black rounded-xl gap-5 px-4 py-2 my-5"
                sx={(theme) => ({
                  backgroundColor: theme.palette.secondary.main,
                })}
              >
                <FuseSvgIcon className="text-48 text-white" size={20}>
                  material-solid:groups
                </FuseSvgIcon>
                <Typography variant="caption" color="#FFF" className="font-bold">
                  {t('ALERT_FOR_ALL')}
                </Typography>
              </Box>
            ) : (
              <div className="w-full">
                {alert.users.length > 0 && (
                  <div className="flex flex-col gap-5">
                    <Typography variant="caption" className="font-medium" display="inline">
                      {t('ALERT_APPLIES_TO_USERS')}:
                    </Typography>
                    <div className="w-full flex gap-2 flex-wrap">
                      {alert.users_data.map((user) => (
                        <div className="w-full flex items-center border-2 !border-black rounded-xl gap-5 px-4 py-2">
                          <FuseSvgIcon className="text-48" size={20}>
                            material-solid:person
                          </FuseSvgIcon>
                          <div>
                            <Typography variant="caption">
                              {user.name} {user.last_name} ({user.email})
                            </Typography>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {alert.teams.length > 0 && (
                  <div className="flex flex-col gap-5">
                    <Typography variant="caption" className="font-medium" display="inline">
                      {t('ALERT_APPLIES_TO_TEAMS')}:
                    </Typography>
                    <div className="w-full flex gap-2 flex-wrap">
                      {alert.teams_data.map((team) => (
                        <div
                          key={team.id}
                          className="w-full flex items-center border-2 !border-black rounded-xl gap-5 px-4 py-2"
                        >
                          <FuseSvgIcon className="text-48" size={20}>
                            material-solid:group
                          </FuseSvgIcon>
                          <div>
                            <Typography variant="body1" className="font-bold">
                              {team.name}
                            </Typography>
                            <Typography variant="caption">{team.description}</Typography>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {alert.email_notification && alert.emails_to_notify.length > 0 && (
                  <div className="flex flex-col gap-5">
                    <Typography variant="caption" className="font-medium" display="inline">
                      {t('NOTIFICATION_BE_SEND_EMAILS')}:
                    </Typography>
                    <div className="w-full flex gap-2 flex-wrap">
                      {alert.emails_to_notify.map((email) => (
                        <div
                          key={email}
                          className="w-full flex items-center border-2 !border-black rounded-xl gap-5 px-4 py-2"
                        >
                          <FuseSvgIcon className="text-48" size={20}>
                            material-solid:email
                          </FuseSvgIcon>

                          <Typography variant="body1" className="font-bold">
                            {email}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          <div>
            {alert.platform_notification && (
              <Box
                className="w-full flex items-center border-2 !border-black rounded-xl gap-5 px-4 py-2 my-5"
                sx={(theme) => ({
                  backgroundColor: theme.palette.secondary.main,
                })}
              >
                <FuseSvgIcon className="text-48 text-white" size={20}>
                  material-solid:notifications
                </FuseSvgIcon>
                <Typography variant="caption" color="#FFF" className="font-bold">
                  {t('WILL_NOTIFIED_IN_OPTACHECK')}
                </Typography>
              </Box>
            )}
          </div>
          {checkRole('platform_support') && (
            <>
              <div className="w-full py-4">
                <Divider />
              </div>
              <div className="">
                <Typography variant="caption" className="font-medium">
                  {t('CREATED_BY')}:
                </Typography>
                <Typography variant="caption">
                  {`${alert.created_by_data?.name} ${alert.created_by_data?.last_name} (${alert.created_by_data?.email})`}
                </Typography>
              </div>
              <div>
                <Typography variant="caption" className="font-medium">
                  {t('CREATION_DATE')}:
                </Typography>
                <Typography variant="caption">
                  {format(parseISO(alert.date_created), 'dd/MM/y hh:mm aaaa ')}
                </Typography>
              </div>
              <div>
                <Typography variant="caption" className="font-medium">
                  ID:
                </Typography>
                <Typography variant="caption">{alert.id}</Typography>
              </div>
            </>
          )}
          {checkPermission(['edit_alert', 'delete_alert']) && (
            <div className="absolute right-24 bottom-24 flex gap-10">
              <Tooltip title={t('EDIT')} placement="top">
                <Fab color="secondary" size="small" aria-label="edit" onClick={onEdit}>
                  <FuseSvgIcon size={20}>heroicons-solid:pencil</FuseSvgIcon>
                </Fab>
              </Tooltip>
              {checkPermission('delete_alert') && (
                <Tooltip title={t('DELETE')} placement="top">
                  <Fab color="error" size="small" aria-label="remove" onClick={openDeleteModal}>
                    <FuseSvgIcon size={20}>material-solid:delete</FuseSvgIcon>
                  </Fab>
                </Tooltip>
              )}
            </div>
          )}
        </div>
      )}
    </motion.div>
  );
}

export default AlertsSidebarContent;
