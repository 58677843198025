import i18next from 'i18next';
import { lazy } from 'react';

import authRoles from '../../../auth/authRoles';
import en from '../i18n/en';
import es from '../i18n/es';

const RestorePasswordPage = lazy(() => import('./RestorePasswordPage'));
const RestorePasswordActionPage = lazy(() => import('./RestorePasswordActionPage'));

i18next.addResourceBundle('en', 'authApp', en);
i18next.addResourceBundle('es', 'authApp', es);

const ForgotPassword = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: 'restore-password',
      element: <RestorePasswordPage />,
    },
    {
      path: 'restore-password-action',
      element: <RestorePasswordActionPage />,
    },
  ],
};

export default ForgotPassword;
