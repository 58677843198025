import i18next from 'i18next';
import en from './navigation-i18n/en';
import es from './navigation-i18n/es';

/**
 * This file will contain the navigation configuration.
 * The navigation configuration will be used to build the navigation
 */
i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('es', 'navigation', es);

/**
 * This is the navigation configuration.
 * It is an array of objects, each object represents a menu item.
 * Each menu item can have the following properties:
 * - id: The id of the menu item
 * - title: The title of the menu item
 * - type: The type of the menu item (item, collapse)
 * - - item: A menu item that is a link
 * - - collapse: A menu item that has children
 * - icon: The icon of the menu item (You can use any icon from the Material-UI icon library)
 */
const navigationConfig = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    icon: 'heroicons-solid:chart-pie',
    url: `dashboard`,
  },
  {
    id: 'management.clients',
    title: 'clients',
    type: 'item',
    icon: 'material-solid:recent_actors',
    url: `clients`,
    translate: 'clients',
    permissionsRequired: ['view_clients_all'],
  },
  {
    id: 'assignments',
    title: 'assignments',
    type: 'collapse',
    icon: 'heroicons-outline:check-circle',
    translate: 'assignments',
    children: [
      {
        id: 'assignments.assignmentList',
        title: 'assignmentList',
        type: 'item',
        icon: 'heroicons-solid:clipboard-check',
        url: `assignments`,
        translate: 'assignmentList',
        permissionsRequired: ['view_assignments_all'],
      },
      {
        id: 'assignments.forms',
        title: 'forms',
        type: 'item',
        icon: 'material-solid:assignment',
        url: `forms`,
        translate: 'forms',
        permissionsRequired: ['view_assignments_all'],
      },
    ],
  },
  {
    id: 'locations',
    title: 'locations',
    type: 'item',
    icon: 'material-solid:location_on',
    translate: 'location',
    url: 'tracking',
    moduleActiveRequire: 'tracking',
  },
  {
    id: 'management',
    title: 'management',
    type: 'collapse',
    icon: 'material-solid:manage_accounts',
    translate: 'management',
    children: [
      {
        id: 'management.users',
        title: 'users',
        type: 'item',
        icon: 'heroicons-solid:users',
        url: `users`,
        translate: 'users',
        permissionsRequired: ['view_users_all'],
      },
      {
        id: 'management.projects',
        title: 'projects',
        type: 'item',
        icon: 'heroicons-solid:light-bulb',
        url: `projects`,
        translate: 'projects',
        permissionsRequired: ['view_projects_all'],
      },
      {
        id: 'management.tags',
        title: 'tags',
        type: 'item',
        icon: 'feather:tag',
        url: `tags`,
        translate: 'tags',
        permissionsRequired: ['view_tags_all'],
      },
      {
        id: 'management.alerts',
        title: 'alerts',
        type: 'item',
        icon: 'feather:alert-triangle',
        url: `alerts`,
        translate: 'alerts',
        permissionsRequired: ['view_alert'],
      },
      {
        id: 'management.csv-load',
        title: 'csv_load',
        type: 'item',
        icon: 'material-solid:cloud_upload',
        url: `csv-load`,
        translate: 'csv_load',
        superUserRequired: true,
        minWidthPixelsToShow: 600,
      },
      {
        id: 'management.settings',
        title: 'settings',
        type: 'item',
        icon: 'material-solid:settings',
        url: `settings`,
        translate: 'settings',
        superUserRequired: true,
      },
    ],
  },
];

export default navigationConfig;
