import { combineReducers } from '@reduxjs/toolkit';
import projects, { resetProjectsState } from './projectsSlice';
import alerts, { resetAlertsState } from './alertsSlice';
// eslint-disable-next-line import/no-cycle
import users, { resetUsersState } from './usersSlice';
import tags, { resetTagsState } from './tagsSlice';
import clients, { resetClientsState } from './clientsSlice';
import roles, { resetRolesState } from './rolesSlice';
import teams, { resetTeamsState } from './teamsSlice';
import csvLoads, { resetCsvLoadsState } from './csvLoadsSlice';
import notifications, { resetNotificationsState } from './notificationsSlice';
import events, { resetEventsState } from './eventsSlice';
import applications, { resetApplicationsState } from './applicationsSlice';
import syncHistory, { resetSynchronizationsState } from './syncHistorySlice';

const reducer = combineReducers({
  ...users,
  projects,
  alerts,
  tags,
  clients,
  roles,
  teams,
  csvLoads,
  notifications,
  events,
  applications,
  syncHistory,
});

export const resetManagementState = (dispatch) => {
  dispatch(resetProjectsState());
  dispatch(resetAlertsState());
  dispatch(resetUsersState());
  dispatch(resetTagsState());
  dispatch(resetClientsState());
  dispatch(resetRolesState());
  dispatch(resetTeamsState());
  dispatch(resetCsvLoadsState());
  dispatch(resetNotificationsState());
  dispatch(resetEventsState());
  dispatch(resetApplicationsState());
  dispatch(resetSynchronizationsState());
};

export default reducer;
