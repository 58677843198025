/**
 * this file will contain all the translations in spanish
 */
const locale = {
  ACCEPT: 'Aceptar',
  ACCESS_KEYS: 'Llaves de acceso al API',
  ADD_CSV_LOAD: 'Cargar CSV',
  ADD_FIELD: 'Agregar campo',
  ADD_NEW_PLACE: 'Agregar un nuevo lugar',
  ADD_NOW: 'Agregar inmediatamente al escritorio',
  ADD_NOW_AND_ASSIGN_PASSWORD: 'Agregar inmediatamente al escritorio y asignar una contraseña',
  ADD_USER: 'Agregar usuario',
  ADDRESS: 'Dirección',
  ALARMS: 'Alarmas',
  ALERT: 'Alerta',
  ALERT_APPLIES_TO_GEOFENCES: 'Alerta aplica a estas geocercas',
  ALERT_APPLIES_TO_TEAMS: 'La alerta aplica para los siguientes equipos',
  ALERT_APPLIES_TO_USERS: 'La alerta aplica para los siguientes usuarios',
  ALERT_CREATED: 'Alerta "{{name}}" creada',
  ALERT_DELETED: 'Alerta "{{name}}" eliminada',
  ALERT_DESC:
    'Aquí puedes configurar alertas para que la plataforma detecte eventos que ocurren cuando los usuarios están operando, por ejemplo: detección de velocidades máximas',
  ALERT_FILTERS: 'Filtro de Alertas',
  ALERT_FOR_ALL: 'La alerta es para todos los usuarios',
  ALERT_FOR_GEOFENCE: 'Alerta para geocercas',
  ALERT_FOR_ITS: 'Alerta por falta de actividad',
  ALERT_FOR_MXS: 'Alerta de velocidad máxima',
  ALERT_UPDATED: 'Alerta "{{name}}" actualizada',
  ALERTS: 'Alertas',
  ALL_PERMISSIONS: 'Todos los permisos',
  ALL_PLACES_WILL_REMOVED: 'Todos los lugares relacionados a "{{clientName}}" seran eliminados.',
  ALREADY_USE_INTERNAL_CODE: 'El código interno "{{internal_code}}" ya lo usa otro cliente',
  ALREADY_USE_INVOICE_TAX_IDENTIFIER:
    'El código triButario "{{invoice_tax_identifier}}" ya lo usa otro cliente',
  ALREADY_USE_NAME: 'El nombre "{{name}}" ya lo usa otro cliente',
  APPLICATION_DETAILS: 'Detalles de instalación',
  APPLICATIONS_FILTERS: 'Filtrar instalaciones',
  APPLIED_FILTERS: 'filtros aplicados',
  APPLY: 'Aplicar',
  ARE_SURE_DELETE_ALERT: '¿Está seguro de que desea eliminar la alerta "{{name}}"?',
  ARE_SURE_DELETE_CLIENT: '¿Está seguro de que desea eliminar el cliente "{{name}}"?',
  ARE_SURE_DELETE_PLACE: '¿Está seguro de que desea eliminar el lugar "{{name}}"?',
  ARE_SURE_DELETE_PROJECT: '¿Está seguro de que desea eliminar el proyecto "{{name}}"?',
  ARE_SURE_DELETE_ROLE: '¿Está seguro de que desea eliminar el rol "{{name}}"?',
  ARE_SURE_DELETE_TAG: '¿Está seguro de que desea eliminar la etiqueta "{{name}}"?',
  ARE_SURE_DELETE_TEAM: '¿Está seguro de que desea eliminar el equipo "{{name}}"?',
  ARE_SURE_LEAVE_WS: '¿Está seguro de que desea abandonar {{wsName}}?',
  ARE_SURE_REMOVE_USER: '¿Está seguro de que desea remover el usuario "{{name}}", de {{wsName}}?',
  ARE_SURE_THIS_ACTION: '¿Está seguro de esta acción?',
  ASSIGN_DATA_USER: 'Asignale un nombre y apellido al nuevo usuario',
  ASSIGN_LOCATION_THIS_CLIENT: 'Asignar una ubicación a este cliente',
  ASSIGN_NEW_PASSWORD: 'Asignale una contraseña al nuevo usuario',
  ASSIGN_PLACES_THIS_CLIENT: 'Asignar lugares a este cliente',
  ASSIGN_ROLES: 'Asignar roles',
  ASSIGN_ROLES_USER: 'Asignale roles al nuevo usuario',
  'ASSIGNMENT-IDLENESS': 'Inactividad de usuario en tareas',
  'ASSIGNMENT-IDLENESS_DESP': 'Inactividad en tareas',
  ASSIGNMENTS: 'Tareas',
  AUXILIARY_OFFICE: 'Oficina Auxiliar',
  BOOKMARK: 'Destacar',
  BOOKMARK_DOC:
    'destacar: puedes indicar si la tarea debe de aparecer como "Destacada" (Bookmark) en la aplicación del usuario. Se aceptan los valores "true", "1" o "si". Dejalo en blanco si no deseas destacar la tarea.',
  BRANCH_OFFICE: 'Sucursal',
  BY_DATE_CREATED: 'Por fecha creada',
  BY_DATE_SYNC: 'Por fecha de sincronización',
  BY_LEVEL: 'Por nivel',
  BY_STATUS: 'Por estado',
  BY_TEAM: 'Por Equipo',
  BY_TYPE: 'Por tipo',
  BY_USER: 'Por usuario',
  BY_VERSION: 'Por version',
  BY_VERSION_DESC: 'Coloque la version en formato major.minor.patch por ejemplo: 1.24.2',
  CAN_ADD_FIELDS: 'Puedes agregar campos personalizados',
  CANCEL: 'Cancelar',
  CARRIER: 'Compañía',
  CHANGE_PASSWORD: 'Cambiar contraseña',
  CHECKED_DATE: 'Fecha de revisión',
  CLIENT: 'Cliente',
  CLIENT_CREATED: 'Cliente "{{name}}" creado',
  CLIENT_DELETED: 'Cliente "{{name}}" eliminado',
  CLIENT_DESC: 'Registra tus clientes para organizar mejor las tareas',
  CLIENT_DOC:
    'cliente: el cliente al cual vincular la tarea, se puede ingresar el nombre, código interno o el ID del cliente.',
  CLIENT_MORE_ONE_LOCATION: 'Este cliente tiene más de una ubicación.',
  CLIENT_MORE_ONE_LOCATION_DESCR:
    'Si su cliente tiene varias ubicaciones, por ejemplo sucursales, tiendas o bodegas, le recomendamos agregarlas en la pestaña de "Lugares". Si tu cliente solo tiene una ubicación entonces puedes indicarlo en la pestaña de "Ubicación"',
  CLIENT_UPDATED: 'Cliente "{{name}}" actualizado',
  'CLIENT-ASSIGNMENT': 'Tarea para el cliente',
  'CLIENT-GEOFENCE': 'Ingreso geocerca de cliente',
  CLIENTS: 'Clientes',
  CLIENTS_FOUND: '{{count}} clientes encontrados',
  CONFIRM: 'Confirmar',
  CONFIRM_NEW_PASSWORD: 'Confirmar nueva contraseña',
  CONSTRUCTION_SITES: 'Sitios de construcción',
  CONTACT_EMAIL: 'Email de contacto',
  CONTACT_NAME: 'Nombre de contacto',
  CONTACT_PHONE: 'Teléfono de contacto',
  CONTINUE_ADMIN_USER_TEAM: 'Continuar a administrar los miembros del equipo',
  CONTINUE_LOCATION: 'Continuar a indicar la ubicación',
  COPIED: 'Copiado',
  COPY: 'Copiar',
  CREATE: 'Crear',
  CREATE_ACCESS_KEY: 'Nueva llave de acceso',
  CREATE_ALARM_MESSAGES: 'Crear mensajes de alerta',
  CREATE_ALERT: 'Crear alerta',
  CREATE_ASSIGNMENT: 'Crear tarea',
  CREATE_CLIENT: 'Crear cliente',
  CREATE_FORM: 'Crear formulario',
  CREATE_FULFILLMENT: 'Crear cumplimiento',
  CREATE_GEOFENCE: 'Crear geocerca',
  CREATE_GEOFENCE_SHAPE: 'Crear nueva geocerca',
  CREATE_NOTE: 'Crear nota',
  CREATE_PROJECT: 'Crear proyecto',
  CREATE_SCHEDULE_RECORDS: 'Crear registros de horarios',
  CREATE_TEAM: 'Crear equipo',
  CREATE_USER: 'Nuevo usuario',
  CREATED_BY: 'Creada por',
  CREATION_DATE: 'Fecha de creación',
  CSV_DESC: 'Por medio de archivos CSV puedes ingresar datos a por mayor',
  CSV_LOAD_ERRORS: 'Errores en la carga de csv',
  CSV_LOAD_ERRORS_DEPS:
    'Estas son las líneas que no se pudieron procesar, debido a un problema encontrado durante el procesamiento.\nColoque el puntero del ratón sobre los cuadros rojos para conocer el detalle del error. \nDescarga el CSV que contiene los errores, corrigelos y vuelve a hacer una nueva carga con las correcciones.',
  CSV_REQUIRED_FIELDS_NOTE: 'El campo titulo y usuario son obligatorios, el resto son opcionales.',
  CSV_TITLE: 'Carga de CSV',
  CURRENT_PASSWORD: 'Contraseña actual',
  DASHBOARD: 'Tablero',
  DATE_DUE: 'Fecha-Limite',
  DATE_DUE_DOC:
    'fecha-limite: fecha limite que se desea colocar a la tarea, se aceptan varios formatos: YYYY-MM-DD DD-MM-YYYY MM-DD-YYYY',
  DATE_PROCESSED: 'Fecha de procesamiento',
  DATE_SYNC: 'fecha de sincronización',
  DAY_CHECK_IN_OUT: 'Día de visita',
  DAYS_ALERT_APPLIES: 'Días que aplica la alerta',
  DELETE: 'Eliminar',
  DELETE_ACCESS_KEY: 'Eliminar llave de acceso',
  DELETE_ALERT: 'Eliminar alerta',
  DELETE_ASSIGNMENT: 'Eliminar tarea',
  DELETE_CLIENT: 'Eliminar cliente',
  DELETE_FORM: 'Eliminar formulario',
  DELETE_FULFILLMENT: 'Eliminar cumplimiento',
  DELETE_GEOFENCE: 'Eliminar geocerca',
  DELETE_GEOFENCE_SHAPE: 'Eliminar geocerca',
  DELETE_NOTE: 'Eliminar nota',
  DELETE_PROJECT: 'Eliminar proyectos',
  DELETE_SCHEDULE_RECORDS: 'Eliminar registros de horarios',
  DELETE_TEAM: 'Eliminar equipos',
  DELETE_USER_LOCATION: 'Eliminar ubicación de un usuario',
  DESCRIPTION: 'Descripción',
  DESCRIPTION_DOC: 'descripcion: texto con un máximo de 255 caracteres',
  DETAILS: 'Detalles',
  DEVICE_ID: 'ID de dispostivo',
  DEVICE_TYPE: 'Tipo de dispositivo',
  DOWNLOAD_CSV_ERRORS: 'Descargar CSV con errores',
  DOWNLOAD_CSV_EXAMPLE: 'Descargar ejemplo CSV',
  DOWNLOAD_EXAMPLE: 'Descargar ejemplo',
  DOWNLOAD_USERS_CSV: 'Descargar usuarios por archivo CSV',
  EDIT: 'Editar',
  EDIT_ALARM_MESSAGES: 'Editar mensajes de alerta',
  EDIT_ALERT: 'Editar alerta',
  EDIT_ASSIGNMENT: 'Editar tarea',
  EDIT_CLIENT: 'Editar cliente',
  EDIT_FORM: 'Editar formulario',
  EDIT_FULFILLMENT: 'Editar cumplimiento',
  EDIT_GEOFENCE: 'Editar geocerca',
  EDIT_GEOFENCE_SHAPE: 'Editar geocerca',
  EDIT_GPS_DEVICE: 'Editar dispositivo GPS',
  EDIT_NOTE: 'Editar nota',
  EDIT_PROJECT: 'Editar proyecto',
  EDIT_TEAM: 'Editar equipo',
  EDITION_DATE: 'Fecha de edición',
  EMAIL_CANNOT_EMPTY: 'El campo e-mail no puede estar vacío',
  EMAIL_VALID: 'Ingrese un correo electrónico válido',
  ENTER_EMAIL_USER: 'Ingresa el correo del nuevo usuario',
  ERR_UPLOAD_FILE: 'Ocurrio un error con el archivo, intente subirlo de nuevo, porfavor',
  ERROR: 'Error',
  ERROR_CLIENT_NAME_ALREADY_EXISTS:
    'Se ha detectado que existe otro cliente con los mismos datos, verifique si no esta duplicando el cliente.',
  ERROR_SAVE_CLIENT: 'Error guardando el cliente',
  EVENTS: 'Eventos',
  EVENTS_DESC: 'Estos son todos los eventos que han pasado en este escritorio',
  FACTORY: 'Fábrica',
  FAILURE_COUNT: 'Recuento de fallas',
  FIELDS_REQUIRED_DOC: 'El campo titulo y usuario son obligatorios, el resto son opcionales.',
  FILE_LOAD: 'Archivo cargado',
  FILE_MUST_BE_CSV: 'El archivo debe tener la extensión CSV',
  FILE_SIZE: 'Tamaño de archivo',
  FILTERS: 'Filtros',
  FILTERS_DESC: 'Puedes aplicar un solo filtro o varios filtros a la vez',
  FIRST_INSTALLATION: 'primera instalación',
  FOR_ALL_USERS: 'Para todos los usuarios',
  FOR_THIS_USERS: 'Para estos usuarios',
  FORMS: 'Formularios',
  FORMS_DOC:
    'formularios: los formularios que se desea adjuntar a la tarea, se acepta ingresar varios formularios separados por coma ",". Se puede colocar el nombre del formulario o bien su ID.',
  FROM_DATE: 'Desde',
  FULFILLMENTS: 'Cumplimientos',
  GENERATE_PASSWORD: 'Generar contraseña',
  GENERATE_PASSWORD_HELP: 'Tome nota de esta contraseña, ya que no será visible de nuevo',
  GEOFENCE: 'Geocerca',
  GEOFENCE_RULE: 'Entradas y salidas de la geocerca',
  'GEOFENCE-ARRIVE': 'Llego a geocerca',
  'GEOFENCE-LEAVE': 'Salio de geocerca',
  GEOFENCES: 'Geocercas',
  GEOFENCES_ALERT_APPLIES: 'Aplica solo para estas geocercas',
  HEADQUARTERS: 'Sede',
  IF_CLIENT_MULTIPLE_LOCATION:
    'Si su cliente tiene varias ubicaciones, por ejemplo sucursales, tiendas o almacenes, le recomendamos marcar esta casilla',
  IF_CLIENT_SINGLE_LOCATION:
    'Agregre lugares o,Si su cliente tiene una única ubicación, le recomendamos desmarcar esta casilla.',
  INCORRECT_CURRENT_PASSWORD: 'Contraseña actual incorrecta',
  INFORMATION: 'Información',
  INSTALLATIONS: 'Instalaciones',
  INTERNAL_CODE: 'Código interno',
  INVITATION_CODE_IS: 'El código de invitación es',
  INVITATION_CODE_IS_HELP: 'Comparte o guarda este código, porque no se volverá a mostrar',
  INVITE_USING_CODE: 'Invitar usando código',
  INVITE_USING_CODE_HELP: 'La plataforma le generara un código que debe compartir con el usuario',
  INVOICE_NAME: 'Facturar a nombre de',
  INVOICE_TAX: 'Código tributario',
  INVOICE_TAX_EXAMPLE: '123456789012',
  IS_SUPER_USER: 'Es un superusuario',
  IS_SUPER_USER_HELP:
    'Un superusuario tendrá acceso completo con todos los permisos y podrá actuar como propietario del Escritorio',
  ITS: 'Falta de actividad en las tareas',
  ITS_RULE: 'Por falta de actividad en tareas posteriores a las ',
  LAST_DAY_MONTH: 'Último día del mes',
  LAST_INSTALLATION: 'última instalación',
  LAST_NAME: 'Apellidos',
  LAST_NAME_CANNOT_EMPTY: 'El campo apellidos no puede estar vacío',
  LEAVE: 'Abandonar',
  LEAVE_WS: 'Abandonar el Escritorio',
  LINE: 'Línea',
  LOAD: 'Carga',
  LOAD_NUMBER: 'Carga #',
  LOADED_BY: 'Cargado por',
  LOCATION: 'Ubicación',
  LOCATION_INFO_TITLE: 'Indica el tipo de ubicación que usaras para este cliente',
  LOCATION_PLACES: 'Ubicación de los lugares',
  MANUFACTURER: 'Fabricante',
  MANY_LOCATION: 'Varias ubicaciones',
  MANY_LOCATION_INFO:
    'En caso el cliente tenga varios lugares de interés, entonces puedes indicar la ubicación de cada una de sus instalaciones',
  MAX_WITHOUT_ACTIVITY: 'Hora máxima sin actividad',
  'MAX-SPEED': 'Velocidad máxima detectada\t',
  MESSAGE: 'Mensaje',
  MORE_DATA: 'Mas Datos',
  MUST_ENTER_EMAIL: 'Debes ingresar un email',
  MUST_ENTER_VALID_EMAIL: 'Debes ingresar un email valido',
  MXS: 'Velocidad máxima',
  MXS_CANNOT_EMPTY: 'Establece una velocidad máxima',
  MXS_RULE: 'Por velocidades superiores a ',
  MXS_VALID: 'Establece una velocidad máxima válida',
  NAME: 'Nombre',
  NAME_CANNOT_EMPTY: 'El campo nombre no puede estar vacío',
  NEW_ALERT: 'Nueva alerta',
  NEW_CLIENT: 'Nuevo cliente',
  NEW_PASSWORD: 'Nueva contraseña',
  NEW_PROJECT: 'Nuevo Proyecto',
  NEW_ROLE: 'Nuevo rol',
  NEW_TAG: 'Nueva etiqueta',
  NEW_TEAM: 'Nuevo equipo',
  NEW_USER_ADDED: 'Nuevo usuario agregado',
  NEW_USER_INVITED: 'Nuevo usuario invitado',
  NEW_USER_TITLE: 'Nuevo usuario en tu escritorio',
  NEXT: 'Siguiente',
  NO: 'No',
  NO_ALERTS_SHOW: 'No hay alertas para mostrar',
  NO_APPLICATIONS_SHOW: 'No hay instalaciones que mostrar',
  NO_CLIENTS_SHOW: 'No hay clientes para mostrar',
  NO_CSV_LOAD_SHOW: 'Aún no has cargado ningún archivo CSV',
  NO_NOTIFICATIONS_TO_SHOW: 'No hay notificaciones por ahora.',
  NO_OPTION: 'Sin opciones',
  NO_PROJECT_SHOW: 'No hay proyectos para mostrar',
  NO_SYNCHRONIZATIONS_SHOW: 'No hay sincronizaciones para mostrar',
  NO_TEAMS_SHOW: 'No hay equipos para mostrar',
  NORMAL: 'Normal',
  NOTES: 'Notas',
  NOTIFICATION_BE_SEND_EMAILS: 'Se enviará una notificación a los siguientes correos',
  NOTIFICATIONS: 'Notificaciones',
  NOTIFICATIONS_FILTERS: 'Filtros de notificaciones',
  NOTIFY_EMAIL: 'Notificar por correo electrónico',
  NOTIFY_PLATFORM: 'Notificar en OptaCheck',
  OBSERVATION: 'Observación',
  OBSERVATION_DOC: 'observacion: texto con un máximo de 255 caracteres',
  OFF: 'Apagada',
  'OFF/ON': 'Apagada/Encendida',
  ON: 'Encendida',
  OPTIONAL_GIVE_ADDRESS: '(Opcional) proporciona una dirección para localizar al cliente',
  OTHER: 'Otro',
  OTHERS: 'Otros',
  PARKING_LOT: 'Estacionamiento',
  PASSWORD: 'Contraseña',
  PASSWORD_NOT_MATCH: 'Nueva contraseña y su confirmación no coinciden',
  PENDING_INVITATIONS: '--- Invitaciones pendientes de aceptarse ---',
  PERMISSIONS: 'Permisos',
  PERMISSIONS_ASSIGNED_THIS_ROLE: 'Permisos asignados a este rol',
  PHONE_NUMBER: 'Número de teléfono',
  PLACE: 'Lugar',
  PLACE_DOC:
    'lugar: si el cliente tiene varias ubicaciones, se puede indicar aquí el lugar a vincular en la tarea, solo se puede ingresar el nombre de lugar o su ID.',
  PLACE_MAKER_CLIENT: 'Arrastra el marcador sobre el mapa para ubicar el cliente',
  PLACE_NAME_EXAMPLE: 'Centro de la ciudad',
  PLACES: 'Lugares',
  PLACES_EXAMPLES: 'Sucursales, tiendas, bodegas, etc',
  PLUS_CODE: 'Código Plus',
  POSITION_TEAM: 'Puesto dentro del equipo (técnico, vendedor, etc)',
  PREVIOUS: 'Anterior',
  PROJECT: 'Proyecto',
  PROJECT_CREATED: 'Proyecto "{{name}}" creado',
  PROJECT_DELETED: 'Proyecto "{{name}}" eliminado',
  PROJECT_DESC:
    'Cuando creas tareas puedes vincularlas a proyectos. Esto te permite organizar mejor las tareas',
  PROJECT_DOC:
    'proyecto: el proyecto al cual vincular la tarea, se puede ingresar el nombre o ID del proyecto.',
  PROJECT_NAME_EXAMPLE: 'Proyecto nuevos productos',
  PROJECT_UPDATED: 'Proyecto "{{name}}" actualizado',
  PROJECTS: 'Proyectos',
  REFRESH: 'Refrescar',
  REMOVE: 'Remover',
  REMOVE_FILTERS: 'Remover filtros',
  REMOVE_FROM_WS: 'Remover del Escritorio',
  REMOVE_USER_FROM_WS: 'Remover usuario del Escritorio',
  RESTRICT_ONE_USER_LOCATION: 'Restringir ubicación de un usuario',
  ROLE_CREATED: 'Rol "{{name}}" creado',
  ROLE_DELETED: 'Rol "{{name}}" eliminado',
  ROLE_UPDATED: 'Rol "{{name}}" actualizado',
  ROLES: 'Roles',
  ROLES_DESCR: 'Crea roles para administrar de una mejor manera los permisos de los usuarios',
  RULE: 'Regla',
  SALES_GOAL: 'Meta de ventas',
  SALES_GOAL_EXAMPLE: '$1000 al mes',
  SALESPERSON_CHARGE: 'Vendedor a cargo del cliente',
  SAVE: 'Guardar',
  SCHEDULES: 'Horarios',
  SEARCH_ALERT: 'Buscar Alerta',
  SEARCH_CLIENT: 'Buscar Cliente',
  SEARCH_CLIENT_BY: 'Buscar por nombre, código interno, dirección, código tributario o contacto',
  SEARCH_PERMISSIONS: 'Buscar permisos',
  SEARCH_PLACE: 'Busca lugares en el mundo',
  SEARCH_PROJECT: 'Buscar Proyecto',
  SEARCH_ROL: 'Buscar Rol',
  SEARCH_TEAM: 'Buscar equipo',
  SEARCH_USERS: 'Buscar Usuarios',
  SEE_APPLIED_FILTERS: 'Ver filtros aplicados',
  SEE_INSTALLATION: 'Ver instalación',
  SELECT: 'Seleccionar',
  SELECT_A_COLOR: 'Seleccione un color',
  SELECT_WORKSPACE: 'Seleccione un escritorio',
  SELLER: 'Vendedor',
  SEND_EMAIL: 'Enviar un correo  de invitación',
  SEND_EMAIL_HELP:
    'De esta manera, deberá esperar a que el usuario revise su correo electrónico para confirmar su entrada',
  'SERVER-TO-USER_DESP': 'Notificación de procesos',
  SET_LOCATION_HERE: 'Establecer ubicación aquí',
  SHARE_ASSIGNMENT: 'Compartir tarea',
  SHARE_CHECK_IN_OUT: 'Compartir visita',
  SHARE_USER_LOCATION: 'Compartir ubicación de usuarios',
  SHARE_VISIT: 'Compartir visita',
  SHOW_CSV_ERRORS: 'Mostrar Errores en el archivo CSV',
  SINGLE_LOCATION: 'Una Ubicación',
  SINGLE_LOCATION_INFO: 'El cliente solo tiene un lugar físico donde se le puede ubicar',
  STATUS: 'Estado',
  STORE: 'Tienda',
  SUCCESS_COUNT: 'Recuento de éxitos',
  SUPERUSER: 'Super usuario',
  SUPERUSERS: 'Super usuarios',
  SUPPORTED_FORMAT: 'Formatos Soportados',
  SYNC_HISTORY: 'Sincronizaciones',
  SYNC_HISTORY_FILTERS: 'Filtrar sincronizaciones',
  TAG_CREATED: 'Etiqueta "{{name}}" creada',
  TAG_DELETED: 'Etiqueta "{{name}}" eliminada',
  TAG_DESC:
    'Puedes crear etiquetas para ayudarte a administrar geocercas, tareas, visitas, notas, etc.',
  TAG_NAME_REPEATED: 'Ya existe una etiqueta con el nombre "{{name}}"',
  TAG_UPDATED: 'Etiqueta "{{name}}" actualizada',
  TAGS: 'Etiquetas',
  TAGS_DOC:
    'etiquetas: se pueden indicar las etiquetas con la que se desea marcar la tarea, se pueden ingresar varias etiquetas separadas por coma ",". Se puede indicar el nombre o el ID de las etiquetas.',
  TEAM: 'Equipo',
  TEAM_CREATED: 'Equipo "{{name}}" creado',
  TEAM_DELETED: 'Equipo "{{name}}" eliminado',
  TEAM_IN_CHARGE: 'Equipo a cargo del cliente',
  TEAM_UPDATED: 'Equipo "{{name}}" actualizado',
  TEAMS: 'Equipos',
  TEAMS_DESCR: 'Agrupa a los usuarios en equipos',
  TEXT_DOC_1:
    'Puedes subir archivos con extensión CSV, estos los puedes crear usando Excel (o programas similares), el formato soportado para las cabeceras del archivo son las siguientes',
  TEXT_DOC_2:
    'Aquí puedes descargar un archivo CSV con las cabeceras ya colocadas. Descargar muestra',
  TEXT_DOC_3: 'La reglas que tiene que seguir cada campo soportado son las siguientes:',
  THERE_NO_PLACES: 'No hay lugares registrados',
  THERE_NO_ROLES: 'No hay roles!',
  THERE_NO_TAGS: 'No hay etiquetas!',
  THERE_NO_USERS: 'No hay usuarios!',
  TITLE: 'Título',
  TITLE_DOC: 'titulo: texto con un máximo de 100 caracteres',
  TO_ALL_USERS: 'Para todos los usuarios',
  TO_DATE: 'Hasta',
  TOTAL_CLIENTS: '{{count}} clientes en total',
  TYPE: 'Tipo',
  TYPE_LOAD: 'Tipo de carga',
  UNKNOWN: 'Desconocida',
  UNKNOWN_INFO:
    'No sabes con exactitud donde esta la ubicación del cliente, opcionalmente podrás dejar una indicación donde crees que está el cliente',
  UNKNOWN_LOCATION: 'ubicación desconocida',
  UPDATED_PASSWORD: 'Contraseña actualizada',
  UPLOAD_CSV: 'Subir CSV',
  UPLOAD_USERS_CSV: 'Cargar usuarios por archivo CSV',
  USER: 'Usuario',
  USER_ADDED: 'Usuario "{{email}}" agregado',
  USER_ALREADY_ADDED: 'El usuario "{{email}}" ya esta agregado en este Escritorio',
  USER_ALREADY_EXIST: 'El usuario "{{email}}" ya existe en OptaCheck',
  USER_DOC:
    'usuario: a quien se desea asignar la tarea, se puede colocar el email como esta en la plataforma, también se soporta el ID del usuario.',
  USER_HAS_ALL_PERMISSIONS: 'Un superusuario tiene todos los permisos y no necesita roles',
  USER_IN_CHARGE: 'El organizador del equipo',
  USER_IN_TEAM: 'Miembros del equipo',
  USER_REMOVED: 'Usuario "{{email}}" removido',
  USER_UPDATED: 'Usuario "{{email}}" actualizado',
  USER_WILL_ADDED: 'Se agregara el usuario "{{email}}" al Escritorio "{{wsName}}"',
  USER_WILL_INVITED: 'Sera invitado el usuario "{{email}}" al Escritorio "{{wsName}}"',
  USER_WITH_THIS_ROLES: 'El usuario {{name}} {{last_name}} ({{email}}) tiene los siguientes roles',
  'USER-LOCATION_DESP': 'Notificación de ubicación',
  'USER-TO-USER_DESP': 'Notificación de usuario a usuario',
  USERS: 'Usuarios',
  USERS_WITH_THIS_ROLE: 'Usuarios con este rol',
  VALUE: 'Valor',
  VERSION: 'Versión',
  VERY_LARGE_FILE: 'Archivo muy grande',
  VIEW_ACCESS_KEY_ALL: 'Ver llaves de acceso',
  VIEW_ALARM_MESSAGES_ALL: 'Ver todos los mensajes de alertas',
  VIEW_ALERT: 'Ver alertas',
  VIEW_ASSIGNMENTS_ALL: 'Ver tareas',
  VIEW_ASSIGNMENTS_FILE_ALL: 'Ver archivos de tareas',
  VIEW_CHECKIN_CHECKOUT_ALL: 'Ver visitas',
  VIEW_CLIENTS_ALL: 'Ver clientes',
  VIEW_DAILY_RECORD: 'Ver informe diario',
  VIEW_DASHBOARD_MAP: 'Ver mapa en dashboard',
  VIEW_DASHBOARD_STATS: 'Ver estadísticas en dashboard',
  VIEW_DELETED_HISTORY: 'Historial de elementos eliminados',
  VIEW_EVENTS_ALL: 'Ver todos los eventos',
  VIEW_FORM_FIELD_STATS: 'Ver estadísticas de formulario',
  VIEW_FORMS_ALL: 'Ver formularios',
  VIEW_FULFILLMENT: 'Ver cumplimiento de tareas',
  VIEW_GEOFENCE_RECORD_ALL: 'Ver todos los registros de geocercas',
  VIEW_GEOFENCE_SHAPE_ALL: 'Ver todas las geocercas',
  VIEW_GEOFENCES_ALL: 'Ver geocercas',
  VIEW_GPS_DEVICES_ALL: 'Ver todos los dispositivos GPS',
  VIEW_MAP_PAGE: 'Ver la página de mapas',
  VIEW_NOTE_ALL: 'Ver todas las notas',
  VIEW_PLACES_ALL: 'Ver lugares',
  VIEW_PROJECTS_ALL: 'Ver proyectos',
  VIEW_REPORTS_GENERATION: 'Ver generación de informes',
  VIEW_SCHEDULE_RECORDS: 'Ver registros de horarios',
  VIEW_TEAMS_ALL: 'Ver equipos',
  VIEW_USER_LOCATION_ALL: 'Ver todas las ubicaciones de usuarios',
  VIEW_USER_LOCATION_REGISTER: 'Ver registro de ubicaciones de usuarios',
  VIEW_USERS_ACTIVE_ALL: 'Ver todos los usuarios activos',
  VIEW_USERS_ALL: 'Ver usuarios',
  WAREHOUSE: 'Almacén',
  WARNING: 'Advertencia',
  WILL_NOTIFIED_IN_OPTACHECK: 'Será notificado en OptaCheck',
  WILL_SEND_EMAIL_USER: 'Se enviará un correo al usuario para que acepte la invitación',
  'WORKSPACE-TO-USER_DESP': 'Notificación de Escritorio',
  YES: 'Sí',
  VIEW_TAGS_ALL: 'Ver etiquetas',
  EDIT_TAG: 'Editar etiqueta',
  DELETE_TAG: 'Eliminar etiqueta',
  CREATE_TAG: 'Crear etiqueta',
};

export default locale;
