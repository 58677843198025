import * as React from 'react';
import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import history from '@history';
import { accessToWorkspace } from 'app/store/workspaceSlice';
import { getOrganization, setDataOrganization } from 'app/store/organizationSlice';
import { loginUserFailed, logoutUser, setUser } from 'app/store/userSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import jwtService from './services/jwtService';
import authRoles from './authRoles';

const AuthContext = React.createContext();

function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(undefined);
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    jwtService.on('onAutoLogin', () => {
      // dispatch(showMessage({ message: 'Signing in with JWT' }));

      /**
       * Sign in and retrieve user data with stored token
       */
      jwtService
        .signInWithToken()
        .then((user) => {
          success(user, 'Signed in with JWT');
        })
        .catch((error) => {
          pass(error.message);
        });
    });

    jwtService.on('onLogin', (user) => {
      success(user, 'Signed in');
    });

    jwtService.on('onLogout', () => {
      pass('Signed out');

      dispatch(logoutUser());
    });

    jwtService.on('onLoginFailed', (message) => {
      pass(message);

      dispatch(loginUserFailed());
    });

    jwtService.on('onAutoLogout', (message) => {
      pass(message);

      dispatch(logoutUser());
    });

    jwtService.on('onNoAccessToken', () => {
      pass();
    });

    jwtService.on('onServerError', (message) => {
      // pass(message);
      dispatch(showMessage({ message, variant: 'error' }));
    });

    jwtService.on('Forbidden', (message) => {
      // pass(message);
      dispatch(showMessage({ message, variant: 'error' }));
    });

    jwtService.on('onSignUpFailed', (message) => {
      dispatch(showMessage({ message, variant: 'error' }));
    });

    jwtService.on('onPasswordRestoresFailed', (message) => {
      dispatch(showMessage({ message, variant: 'error' }));
    });

    jwtService.init();

    /**
     * funtion is call when the login process is successful
     * and redirect the user to the correct page depending on the role and number of organizations and workspaces
     * @param {*} user user data
     */
    async function success(user) {
      const [_, sub, idUrl] = window.location.pathname.split('/');

      try {
        await dispatch(setUser(user));
        if (authRoles.platform.includes(user.role)) {
          if (sub && sub !== 'login') {
            if (sub === 'org') {
              const org = await dispatch(getOrganization(idUrl)).unwrap();
              dispatch(setDataOrganization(org));
            } else if (sub === 'ws') {
              await dispatch(accessToWorkspace(idUrl)).unwrap();
            }
          } else {
            history.push(`/organizations`);
          }
        } else if (authRoles.org.includes(user.role)) {
          dispatch(setDataOrganization(user.organizations[0]));
          if (sub === 'ws') {
            await dispatch(accessToWorkspace(idUrl)).unwrap();
          } else if (sub !== 'org') {
            window.location.replace(`/org/${user.organizations[0].id}`);
          }
        } else if (authRoles.user.includes(user.role)) {
          if (sub && sub !== 'login') {
            if (sub === 'ws') {
              await dispatch(accessToWorkspace(idUrl)).unwrap();
            }
          } else if (user.workspaces.length === 1) {
            const { id } = user.workspaces[0];

            await dispatch(accessToWorkspace(id)).unwrap();

            history.push(`/ws/${id}/dashboard`);
          } else {
            window.location.replace(`/workspaces`);
          }
        }
      } catch (error) {
        console.log(error);
        if (error.message === 'unauthorized') jwtService.logout();
        else if (error.message === 'missing-credentials')
          dispatch(showMessage({ message: 'socket error :/', variant: 'error' }));
        else history.push('/ws-404/');
      } finally {
        setWaitAuthCheck(false);
        setIsAuthenticated(true);
      }
    }

    function pass(message) {
      if (message) {
        dispatch(showMessage({ message }));
      }

      setWaitAuthCheck(false);
      setIsAuthenticated(false);
    }
  }, [dispatch]);

  return waitAuthCheck ? (
    <FuseSplashScreen />
  ) : (
    <AuthContext.Provider value={{ isAuthenticated }}>{children}</AuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
