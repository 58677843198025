/**
 * this file will contain all the translations in English
 */
const locale = {
  ACCEPT: 'Accept',
  ACCESS_KEYS: 'API access keys to API',
  ADD_CSV_LOAD: 'Load CSV',
  ADD_FIELD: 'Add field',
  ADD_NEW_PLACE: 'Add a new place',
  ADD_NOW: 'Immediately add to Workspace',
  ADD_NOW_AND_ASSIGN_PASSWORD: 'Immediately add to Workspace and assign a password',
  ADD_USER: 'Add user',
  ADDRESS: 'Address',
  ALARMS: 'Alarms',
  ALERT: 'Alert',
  ALERT_APPLIES_TO_GEOFENCES: 'Alert applies to these geofences',
  ALERT_APPLIES_TO_TEAMS: 'The alert applies to the following teams',
  ALERT_APPLIES_TO_USERS: 'The alert applies to the following users',
  ALERT_CREATED: 'Alert "{{name}}" created',
  ALERT_DELETED: 'Alert "{{name}}" deleted',
  ALERT_DESC:
    'Here you can configure alerts so that the platform detects events that occur when users are operating, for example: detection of maximum speeds',
  ALERT_FILTERS: 'Alerts Filters',
  ALERT_FOR_ALL: 'The alert is for all users',
  ALERT_FOR_GEOFENCE: 'Alert for geofences',
  ALERT_FOR_ITS: 'Alert for lack of activity',
  ALERT_FOR_MXS: 'Alert for maximum speed',
  ALERT_UPDATED: 'Alert "{{name}}" updated',
  ALERTS: 'Alerts',
  ALL_PERMISSIONS: 'All permissions',
  ALL_PLACES_WILL_REMOVED: 'All places related to "{{clientName}}" will be removed.',
  ALREADY_USE_INTERNAL_CODE:
    'The internal code "{{internal_code}}" is already used by another client',
  ALREADY_USE_INVOICE_TAX_IDENTIFIER:
    'The tax code "{{invoice_tax_identifier}}" is already used by another client',
  ALREADY_USE_NAME: 'The name "{{name}}" is already used by another client',
  APPLICATION_DETAILS: 'Installation details',
  APPLICATIONS_FILTERS: 'Filter installations',
  APPLIED_FILTERS: 'Applied Filters',
  APPLY: 'Apply',
  ARE_SURE_DELETE_ALERT: 'Are you sure to delete the alert "{{name}}"?',
  ARE_SURE_DELETE_CLIENT: 'Are you sure to delete the customer "{{name}}"?',
  ARE_SURE_DELETE_PLACE: 'Are you sure to delete the place "{{name}}"?',
  ARE_SURE_DELETE_PROJECT: 'Are you sure to delete the project "{{name}}"?',
  ARE_SURE_DELETE_ROLE: 'Are you sure to delete the role "{{name}}"?',
  ARE_SURE_DELETE_TAG: 'Are you sure to delete the tag "{{name}}"?',
  ARE_SURE_DELETE_TEAM: 'Are you sure to delete the team "{{name}}"?',
  ARE_SURE_LEAVE_WS: 'Are you sure to leave the Workspace {{wsName}}?',
  ARE_SURE_REMOVE_USER: 'Are you sure to remove the user "{{name}}", from {{wsName}}?',
  ARE_SURE_THIS_ACTION: 'Are you sure about this action?',
  ASSIGN_DATA_USER: 'Assign a name and last name to the new user',
  ASSIGN_LOCATION_THIS_CLIENT: 'Assign a location to this client',
  ASSIGN_NEW_PASSWORD: 'Assign a password to the new user',
  ASSIGN_PLACES_THIS_CLIENT: 'Assign places to this client',
  ASSIGN_ROLES: 'Assign roles ',
  ASSIGN_ROLES_USER: 'Assign roles to the new user',
  'ASSIGNMENT-IDLENESS': 'User inactivity in tasks',
  'ASSIGNMENT-IDLENESS_DESP': 'Inactivity in tasks',
  ASSIGNMENTS: 'Tasks',
  AUXILIARY_OFFICE: 'Auxiliary Office',
  BOOKMARK: 'Bookmark',
  BOOKMARK_DOC:
    "bookmark: if you wish, you can indicate whether the task should appear as bookmarked in the user's application. Supported values are: 'true', '1' and 'yes'. Leave it blank if you don't want to bookmark the task.",
  BRANCH_OFFICE: 'Branch Office',
  BY_DATE_CREATED: 'By date created',
  BY_DATE_SYNC: 'By sync date',
  BY_LEVEL: 'By level',
  BY_STATUS: 'By status',
  BY_TEAM: 'By Team',
  BY_TYPE: 'By type',
  BY_USER: 'By user',
  BY_VERSION: 'Por version',
  BY_VERSION_DESC: 'Put the version in major.minor.patch format, for example: 1.24.2',
  CAN_ADD_FIELDS: 'You can add custom fields',
  CANCEL: 'Cancel',
  CARRIER: 'Company',
  CHANGE_PASSWORD: 'Change password',
  CHECKED_DATE: 'Revision date',
  CLIENT: 'Client',
  CLIENT_CREATED: 'Client "{{name}}" created',
  CLIENT_DELETED: 'Client "{{name}}" deleted',
  CLIENT_DESC: 'Register your customers to better organize tasks',
  CLIENT_DOC:
    'client: the client to which link the task, you can enter the name, internal code or the client ID.',
  CLIENT_MORE_ONE_LOCATION: 'This customer has more than one location.',
  CLIENT_MORE_ONE_LOCATION_DESCR:
    'If your client has multiple locations, for example branches, stores or warehouses, we recommend adding them in the "Places" tab. If your client only has one location then you can indicate it in the "Location" tab',
  CLIENT_UPDATED: 'Client "{{name}}" updated',
  'CLIENT-ASSIGNMENT': 'Assignment for the client',
  'CLIENT-GEOFENCE': 'Client geofence entry',
  CLIENTS: 'Clients',
  CLIENTS_FOUND: '{{count}} clientes encontrados',
  CONFIRM: 'Confirm',
  CONFIRM_NEW_PASSWORD: 'Confirm new password',
  CONSTRUCTION_SITES: 'Construction Sites',
  CONTACT_EMAIL: 'Contact email',
  CONTACT_NAME: 'Contact name',
  CONTACT_PHONE: 'Contact phone number',
  CONTINUE_ADMIN_USER_TEAM: 'Continue to assign  members to the team',
  CONTINUE_LOCATION: 'Continue to indicate location',
  COPIED: 'Copied',
  COPY: 'Copy',
  CREATE: 'Create',
  CREATE_ACCESS_KEY: 'New API access key',
  CREATE_ALARM_MESSAGES: 'Create alert messages',
  CREATE_ALERT: 'Create alert',
  CREATE_ASSIGNMENT: 'Create task',
  CREATE_CLIENT: 'Create customer',
  CREATE_FORM: 'Create form',
  CREATE_FULFILLMENT: 'Create fulfillment',
  CREATE_GEOFENCE: 'Create geofence',
  CREATE_GEOFENCE_SHAPE: 'Create new geofence',
  CREATE_NOTE: 'Create note',
  CREATE_PROJECT: 'Create project',
  CREATE_SCHEDULE_RECORDS: 'Create schedule records',
  CREATE_TEAM: 'Create team',
  CREATE_USER: 'New user',
  CREATED_BY: 'Created by',
  CREATION_DATE: 'Creation date',
  CSV_DESC: 'You can input data in bulk using CSV files',
  CSV_LOAD_ERRORS: 'Errors in the Csv Load',
  CSV_LOAD_ERRORS_DEPS:
    'These are the lines that could not be processed, due to a problem encountered during processing.\nPlace the mouse pointer over the red boxes to see the details of the error. \nDownload the CSV containing the errors, correct them and upload again with the corrections.',
  CSV_REQUIRED_FIELDS_NOTE: 'Title and user fields are required, the rest are optional.',
  CSV_TITLE: 'CSV Upload',
  CURRENT_PASSWORD: 'Current password',
  DASHBOARD: 'Dashboard',
  DATE_DUE: 'Date-due',
  DATE_DUE_DOC:
    'date-due: deadline that you want to set for the task, several formats are accepted: YYYY-MM-DD DD-MM-YYYY MM-DD-YYYY',
  DATE_PROCESSED: 'Processing date',
  DATE_SYNC: 'Sync date',
  DAY_CHECK_IN_OUT: 'Visit day',
  DAYS_ALERT_APPLIES: 'Apply only for these days',
  DELETE: 'Delete',
  DELETE_ACCESS_KEY: 'Delete access key',
  DELETE_ALERT: 'Delete alert',
  DELETE_ASSIGNMENT: 'Delete task',
  DELETE_CLIENT: 'Delete client',
  DELETE_FORM: 'Delete form',
  DELETE_FULFILLMENT: 'Delete fulfillment',
  DELETE_GEOFENCE: 'Delete geofence',
  DELETE_GEOFENCE_SHAPE: 'Delete geofence',
  DELETE_NOTE: 'Delete note',
  DELETE_PROJECT: 'Delete projects',
  DELETE_SCHEDULE_RECORDS: 'Delete schedule records',
  DELETE_TEAM: 'Delete teams',
  DELETE_USER_LOCATION: 'Delete user location',
  DESCRIPTION: 'Description',
  DESCRIPTION_DOC: 'description: text with a maximum of 255 characters',
  DETAILS: 'Details',
  DEVICE_ID: 'Device ID',
  DEVICE_TYPE: 'Device type',
  DOWNLOAD_CSV_ERRORS: 'Download CSV with errors',
  DOWNLOAD_CSV_EXAMPLE: 'Download CSV example',
  DOWNLOAD_EXAMPLE: 'Download example',
  DOWNLOAD_USERS_CSV: 'Download users by CSV file',
  EDIT: 'Edit',
  EDIT_ALARM_MESSAGES: 'Edit alert messages',
  EDIT_ALERT: 'Edit alert',
  EDIT_ASSIGNMENT: 'Edit task',
  EDIT_CLIENT: 'Edit customer',
  EDIT_FORM: 'Edit form',
  EDIT_FULFILLMENT: 'Edit compliance',
  EDIT_GEOFENCE: 'Edit geofence',
  EDIT_GEOFENCE_SHAPE: 'Edit geofence',
  EDIT_GPS_DEVICE: 'Edit GPS device',
  EDIT_NOTE: 'Edit note',
  EDIT_PROJECT: 'Edit project',
  EDIT_TEAM: 'Edit team',
  EDITION_DATE: 'Edition date',
  EMAIL_CANNOT_EMPTY: 'The email field cannot be empty',
  EMAIL_VALID: 'Enter a valid email address',
  ENTER_EMAIL_USER: 'Enter the email of the new user',
  ERR_UPLOAD_FILE: 'An error occurred with the file, please try to upload it again',
  ERROR: 'Error',
  ERROR_CLIENT_NAME_ALREADY_EXISTS:
    'It has been detected that there is another client with the same data, check if it is not duplicating the client.',
  ERROR_SAVE_CLIENT: 'Error saving client',
  EVENTS: 'Events',
  EVENTS_DESC: 'These are all the events that have happened on this desktop',
  FACTORY: 'Factory',
  FAILURE_COUNT: 'Failure count',
  FIELDS_REQUIRED_DOC: 'The title and user field are required, the rest are optional.',
  FILE_LOAD: 'File loaded',
  FILE_MUST_BE_CSV: 'El archivo debe tener la extensión CSV',
  FILE_SIZE: 'File size',
  FILTERS: 'Filters',
  FILTERS_DESC: 'You can apply a single or multiple filters at once',
  FIRST_INSTALLATION: 'First installation',
  FOR_ALL_USERS: 'To all users',
  FOR_THIS_USERS: 'For these users',
  FORMS: 'Forms',
  FORMS_DOC:
    'forms: the forms that you want to attach to the task, it is acceptable to enter several forms separated by a comma ",". You can enter the name of the form or its ID.',
  FROM_DATE: 'From',
  FULFILLMENTS: 'Fulfillments',
  GENERATE_PASSWORD: 'Generate password',
  GENERATE_PASSWORD_HELP: 'Take a note of this password, because it will not be visible again',
  GEOFENCE: 'geofence',
  GEOFENCE_RULE: 'Geofence inputs and outputs',
  'GEOFENCE-ARRIVE': 'I arrive at geofence',
  'GEOFENCE-LEAVE': 'Geofence left',
  GEOFENCES: 'geofences',
  GEOFENCES_ALERT_APPLIES: 'Applies only for these geofences',
  HEADQUARTERS: 'Headquarters',
  IF_CLIENT_MULTIPLE_LOCATION:
    'If your client has multiple locations, for example branches, stores or warehouses, we recommend checking this box.',
  IF_CLIENT_SINGLE_LOCATION:
    'Add locations or,If your customer has a single location, we recommend unchecking this box.',
  INCORRECT_CURRENT_PASSWORD: 'Incorrect current password',
  INFORMATION: 'Information',
  INSTALLATIONS: 'Installations',
  INTERNAL_CODE: 'Internal code',
  INVITATION_CODE_IS: 'The invitation code is',
  INVITATION_CODE_IS_HELP: 'Share or save this code, because it will not be shown again',
  INVITE_USING_CODE: 'Invite using code',
  INVITE_USING_CODE_HELP: 'The platform will generate a code that you must share with the user',
  INVOICE_NAME: 'Name to use in the invoice',
  INVOICE_TAX: 'Tax code',
  INVOICE_TAX_EXAMPLE: '123456789012',
  IS_SUPER_USER: 'Is a superuser',
  IS_SUPER_USER_HELP:
    'A superuser will have full access with all permissions and will be able to act as the owner of the Workspace',
  ITS: 'Lack of activity in the tasks',
  ITS_RULE: 'Due to lack of activity in tasks after ',
  LAST_DAY_MONTH: 'Last day of the month',
  LAST_INSTALLATION: 'Last installation',
  LAST_NAME: 'Last name',
  LAST_NAME_CANNOT_EMPTY: 'Last name field cannot be empty',
  LEAVE: 'Leave',
  LEAVE_WS: 'Leave the Workspace',
  LINE: 'Line',
  LOAD: 'Load',
  LOAD_NUMBER: 'Upload #',
  LOADED_BY: 'Loaded by',
  LOCATION: 'Location',
  LOCATION_INFO_TITLE: 'Indicates the type of location you will use for this client',
  LOCATION_PLACES: 'Location of places',
  MANUFACTURER: 'Manufacturer',
  MANY_LOCATION: 'Multiple locations',
  MANY_LOCATION_INFO:
    'If the client has several places of interest, then you can indicate the location of each of their facilities',
  MAX_WITHOUT_ACTIVITY: 'Maximum time without activity',
  'MAX-SPEED': 'Maximum speed detected',
  MESSAGE: 'Message',
  MORE_DATA: 'More data',
  MUST_ENTER_EMAIL: 'You must enter a email',
  MUST_ENTER_VALID_EMAIL: 'You must enter a valid email',
  MXS: 'Maximum speed',
  MXS_CANNOT_EMPTY: 'Set a top speed',
  MXS_RULE: 'For speeds greater than ',
  MXS_VALID: 'Set a valid top speed',
  NAME: 'Name',
  NAME_CANNOT_EMPTY: 'The name field cannot be empty',
  NEW_ALERT: 'New alert',
  NEW_CLIENT: 'New customer',
  NEW_PASSWORD: 'New password',
  NEW_PROJECT: 'New project',
  NEW_ROLE: 'New role',
  NEW_TAG: 'New tag',
  NEW_TEAM: 'New team',
  NEW_USER_ADDED: 'New user added',
  NEW_USER_INVITED: 'New user invited',
  NEW_USER_TITLE: 'New user on your desktop',
  NEXT: 'Next',
  NO: 'No',
  NO_ALERTS_SHOW: 'No alerts to show',
  NO_APPLICATIONS_SHOW: 'No installations to show',
  NO_CLIENTS_SHOW: 'There are no customers to show',
  NO_CSV_LOAD_SHOW: 'No CSV loads to show',
  NO_NOTIFICATIONS_TO_SHOW: 'There are no notifications for now.',
  NO_OPTION: 'No options',
  NO_PROJECT_SHOW: 'No projects to show',
  NO_SYNCHRONIZATIONS_SHOW: 'No syncs to show',
  NO_TEAMS_SHOW: 'No teams to show',
  NORMAL: 'Normal',
  NOTES: 'Notes',
  NOTIFICATION_BE_SEND_EMAILS: 'A notification will be send to the following list of email',
  NOTIFICATIONS: 'Notifications',
  NOTIFICATIONS_FILTERS: 'Notifications filters',
  NOTIFY_EMAIL: 'Notify by email',
  NOTIFY_PLATFORM: 'Notify in OptaCheck',
  OBSERVATION: 'Observation',
  OBSERVATION_DOC: 'observation: text with a maximum of 255 characters',
  OFF: 'Off',
  'OFF/ON': 'Off/On',
  ON: 'On',
  OPTIONAL_GIVE_ADDRESS: '(Optional) Provide an address to locate the customer',
  OTHER: 'Other',
  OTHERS: 'Others',
  PARKING_LOT: 'Parking Lot',
  PASSWORD: 'Password',
  PASSWORD_NOT_MATCH: 'New password and confirmation do not match',
  PENDING_INVITATIONS: 'Pending invitations',
  PERMISSIONS: 'Permissions',
  PERMISSIONS_ASSIGNED_THIS_ROLE: 'Permissions assigned to this role',
  PHONE_NUMBER: 'Phone number',
  PLACE: 'Place',
  PLACE_DOC:
    'place: if the client has several locations, the place to be linked in the task can be indicated here, only the place name or its ID can be entered.',
  PLACE_MAKER_CLIENT: "Drag the marker to the client's location",
  PLACE_NAME_EXAMPLE: 'Downtown Store',
  PLACES: 'Places',
  PLACES_EXAMPLES: 'Branches, stores, warehouses, etc',
  PLUS_CODE: 'Plus code',
  POSITION_TEAM: 'Position inside the team',
  PREVIOUS: 'Previous',
  PROJECT: 'Project',
  PROJECT_CREATED: 'Project "{{name}}" created',
  PROJECT_DELETED: 'Project "{{name}}" deleted',
  PROJECT_DESC:
    'When you create tasks you can link them to projects. This allows you to better organize tasks',
  PROJECT_DOC: 'project: the project to link the task to, you can enter the project name or ID.',
  PROJECT_NAME_EXAMPLE: 'New products launch',
  PROJECT_UPDATED: 'Project "{{name}}" updated',
  PROJECTS: 'Projects',
  REFRESH: 'Refresh',
  REMOVE: 'Remove',
  REMOVE_FILTERS: 'Remove filters',
  REMOVE_FROM_WS: 'Remove from Workspace',
  REMOVE_USER_FROM_WS: 'Remove user from Workspace',
  RESTRICT_ONE_USER_LOCATION: 'Restrict one user location',
  ROLE_CREATED: 'Role "{{name}}" created',
  ROLE_DELETED: 'Role "{{name}}" deleted',
  ROLE_UPDATED: 'Role "{{name}}" updated',
  ROLES: 'Roles',
  ROLES_DESCR: 'Create roles to better manage user permissions',
  RULE: 'Rule',
  SALES_GOAL: 'Sales goal',
  SALES_GOAL_EXAMPLE: '99-99-9999',
  SALESPERSON_CHARGE: 'Salesperson in charge of the customer',
  SAVE: 'Save',
  SCHEDULES: 'Schedules',
  SEARCH_ALERT: 'Search alert',
  SEARCH_CLIENT: 'Search client',
  SEARCH_CLIENT_BY: 'Search by name, internal code, address, tax code or contact',
  SEARCH_PERMISSIONS: 'Search permissions',
  SEARCH_PLACE: 'Search places in the world',
  SEARCH_PROJECT: 'Search project',
  SEARCH_ROL: 'Search rol',
  SEARCH_TEAM: 'Search team',
  SEARCH_USERS: 'Search users',
  SEE_APPLIED_FILTERS: 'See applied filters',
  SEE_INSTALLATION: 'View installation',
  SELECT: 'Select',
  SELECT_A_COLOR: 'Select a color',
  SELECT_WORKSPACE: 'Select a Workspace',
  SELLER: 'Seller',
  SEND_EMAIL: 'Send an invitation by email',
  SEND_EMAIL_HELP:
    'In this way, you will have to wait for the users to check their email to confirm their entry',
  'SERVER-TO-USER_DESP': 'Process notification',
  SET_LOCATION_HERE: 'Set location here',
  SHARE_ASSIGNMENT: 'Share task',
  SHARE_CHECK_IN_OUT: 'Share visit',
  SHARE_USER_LOCATION: 'Share user location',
  SHARE_VISIT: 'Share visit',
  SHOW_CSV_ERRORS: 'Show Errors in CSV file',
  SINGLE_LOCATION: 'A Location',
  SINGLE_LOCATION_INFO: 'The customer only has one physical location where they can be located',
  STATUS: 'Status',
  STORE: 'Store',
  SUCCESS_COUNT: 'Success count',
  SUPERUSER: 'Superuser',
  SUPERUSERS: 'Superusers',
  SUPPORTED_FORMAT: 'Supported Formats',
  SYNC_HISTORY: 'Sync History',
  SYNC_HISTORY_FILTERS: 'Filter Sync History',
  TAG_CREATED: 'Tag "{{name}}" created',
  TAG_DELETED: 'Tag "{{name}}" deleted',
  TAG_DESC: 'You can create tags to help you manage geofences, tasks, visits, notes, etc',
  TAG_NAME_REPEATED: 'A tag with name "{{name}}" already exists',
  TAG_UPDATED: 'Tag "{{name}}" updated',
  TAGS: 'Tags',
  TAGS_DOC:
    'tags: you can indicate the tags with which you want to mark the task, you can enter several tags separated by a comma ",". You can indicate the name or ID of the tags.',
  TEAM: 'Team',
  TEAM_CREATED: 'Team "{{name}}" created',
  TEAM_DELETED: 'Team "{{name}}" deleted',
  TEAM_IN_CHARGE: 'Team in charge of the customer',
  TEAM_UPDATED: 'Team "{{name}}" updated',
  TEAMS: 'Teams',
  TEAMS_DESCR: 'Groups users into teams',
  TEXT_DOC_1:
    'You can upload files with a CSV extension, you can create these using Excel (or similar programs), the supported format for the file headers are the following',
  TEXT_DOC_2: 'Download a CSV file sample with the headers already placed.',
  TEXT_DOC_3: 'The rules that each supported field must follow are the following:',
  THERE_NO_PLACES: 'There are no registered places',
  THERE_NO_ROLES: 'There are no roles!',
  THERE_NO_TAGS: 'There are no tags!',
  THERE_NO_USERS: 'There are no users!',
  TITLE: 'Title',
  TITLE_DOC: 'title: text with a maximum of 100 characters',
  TO_ALL_USERS: 'To all users',
  TO_DATE: 'To',
  TOTAL_CLIENTS: '{{count}} clientes en total',
  TYPE: 'Type',
  TYPE_LOAD: 'Type load',
  UNKNOWN: 'Unknown',
  UNKNOWN_INFO:
    "You do not know exactly where the client's location is, optionally you can leave an indication where you think the client is",
  UNKNOWN_LOCATION: 'unknown location',
  UPDATED_PASSWORD: 'Updated password',
  UPLOAD_CSV: 'Upload CSV',
  UPLOAD_USERS_CSV: 'Upload users by CSV file',
  USER: 'User',
  USER_ADDED: 'User "{{email}}" added',
  USER_ALREADY_ADDED: 'User with "{{email}}" is already added in this Workspace',
  USER_ALREADY_EXIST: 'User with "{{email}}" already exists in OptaCheck',
  USER_DOC:
    'user: to whom you want to assign the task, you can enter the email as it is on the platform, the user ID is also supported.',
  USER_HAS_ALL_PERMISSIONS: 'A superuser has all permissions and does not need roles',
  USER_IN_CHARGE: 'Team manager',
  USER_IN_TEAM: 'Team members',
  USER_REMOVED: 'User "{{email}}" removed',
  USER_UPDATED: 'Updated "{{email}}" user ',
  USER_WILL_ADDED: 'The user "{{email}}" will be added to the workspace "{{wsName}}"',
  USER_WILL_INVITED: 'The user "{{email}}" will be invited to the workspace "{{wsName}}"',
  USER_WITH_THIS_ROLES: 'the user  {{name}} {{last_name}} ({{email}}) has the following roles',
  'USER-LOCATION_DESP': 'Location notification',
  'USER-TO-USER_DESP': 'User to user notification',
  USERS: 'Users',
  USERS_WITH_THIS_ROLE: 'Users with this role',
  VALUE: 'Value',
  VERSION: 'Version',
  VERY_LARGE_FILE: 'Archivo muy grande',
  VIEW_ACCESS_KEY_ALL: 'View API access keys',
  VIEW_ALARM_MESSAGES_ALL: 'View all alert messages',
  VIEW_ALERT: 'View alerts',
  VIEW_ASSIGNMENTS_ALL: 'View tasks',
  VIEW_ASSIGNMENTS_FILE_ALL: 'View tasks files',
  VIEW_CHECKIN_CHECKOUT_ALL: 'View visits',
  VIEW_CLIENTS_ALL: 'View clients',
  VIEW_DAILY_RECORD: 'View daily report',
  VIEW_DASHBOARD_MAP: 'View map in dashboard',
  VIEW_DASHBOARD_STATS: 'View task statistics in dashboard',
  VIEW_DELETED_HISTORY: 'History of deleted items',
  VIEW_EVENTS_ALL: 'View all events',
  VIEW_FORM_FIELD_STATS: 'View form statistics',
  VIEW_FORMS_ALL: 'View forms',
  VIEW_FULFILLMENT: 'View task fulfillment',
  VIEW_GEOFENCE_RECORD_ALL: 'View all geofence records',
  VIEW_GEOFENCE_SHAPE_ALL: 'View all geofences',
  VIEW_GEOFENCES_ALL: 'View geofences',
  VIEW_GPS_DEVICES_ALL: 'View all GPS devices',
  VIEW_MAP_PAGE: 'View map page',
  VIEW_NOTE_ALL: 'View all notes',
  VIEW_PLACES_ALL: 'View places',
  VIEW_PROJECTS_ALL: 'View projects',
  VIEW_REPORTS_GENERATION: 'View report generation',
  VIEW_SCHEDULE_RECORDS: 'View schedule records',
  VIEW_TEAMS_ALL: 'View teams',
  VIEW_USER_LOCATION_ALL: 'View all user locations',
  VIEW_USER_LOCATION_REGISTER: 'View user location record',
  VIEW_USERS_ACTIVE_ALL: 'View all active users',
  VIEW_USERS_ALL: 'View users',
  WAREHOUSE: 'Warehouse',
  WARNING: 'Warning',
  WILL_NOTIFIED_IN_OPTACHECK: 'Will be notified in OptaCheck',
  WILL_SEND_EMAIL_USER: 'An email will be sent to your contact to accept the invitation',
  'WORKSPACE-TO-USER_DESP': 'Workspace notification',
  YES: 'Yes',
  VIEW_TAGS_ALL: 'View tags',
  EDIT_TAG: 'Edit tag',
  DELETE_TAG: 'Delete tag',
  CREATE_TAG: 'Create tag',
};

export default locale;
