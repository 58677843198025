import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Controller, useFormContext } from 'react-hook-form';
import { format, parseISO } from 'date-fns';
import { roleChecker } from 'app/store/userSlice';
import { selectUserById, selectUsers } from 'app/store/selectsData/usersSlice';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { setSidebarProps } from '../../../store/teamsSlice';

/**
 * User chip component
 */
const UserChip = (props) => {
  const { userID } = props;
  const user = useSelector((state) => selectUserById(state, userID));

  return (
    <ListItemText id={user.id} primary={`${user.name} ${user.last_name}`} secondary={user.email} />
  );
};

/**
 * Information tab of the team
 */
function InformationTab() {
  /**
   * @constant {array} users - the list of users
   * @constant {function} checkRole - the function to check the role of the user
   * @constant {function} t - the function to translate text
   * @constant {object} teamId - the team id
   * @constant {object} control - the control of the form
   * @constant {object} formState - the state of the form
   */
  const users = useSelector(selectUsers);
  const dispatch = useDispatch();
  const checkRole = useSelector(roleChecker);
  const { t } = useTranslation('managementApp');
  const { teamId } = useParams();
  const { control, formState, getValues } = useFormContext();
  const { errors } = formState;

  const continueToUsersTab = () => dispatch(setSidebarProps({ numOfTab: 1 }));

  return (
    <div className="flex flex-wrap p-24">
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            id="user-name"
            className="mb-24"
            label={t('NAME')}
            autoFocus
            error={!!errors.name}
            helperText={errors?.name?.message}
            variant="outlined"
            required
            fullWidth
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t('DESCRIPTION')}
            multiline
            variant="outlined"
            className="mb-24"
            fullWidth
          />
        )}
      />
      {users.length > 0 && (
        <Controller
          render={({ field }) => (
            <FormControl fullWidth className="mb-24">
              <FormLabel className="font-medium text-14" component="legend">
                {t('USER_IN_CHARGE')}
              </FormLabel>
              <Select
                {...field}
                value={field.value || ''}
                variant="outlined"
                renderValue={(userID) => <UserChip userID={userID} />}
                fullWidth
                defaultValue=""
              >
                {users.map((user, index) => (
                  <MenuItem key={index} value={user.id}>
                    <div className="w-full border-t-2 pt-2">
                      <ListItemText
                        id={user.id}
                        primary={`${user.name} ${user.last_name}`}
                        secondary={user.email}
                      />
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          name="user_in_charge"
          control={control}
        />
      )}
      <div className="flex w-full justify-end">
        <Button
          color="secondary"
          variant="contained"
          size="small"
          onClick={continueToUsersTab}
          endIcon={
            <FuseSvgIcon className="text-12" size={15}>
              material-solid:arrow_forward
            </FuseSvgIcon>
          }
        >
          <Typography className="font-bold tracking-tight text-12">
            {t('CONTINUE_ADMIN_USER_TEAM')}
          </Typography>
        </Button>
      </div>
      <div className="w-full py-10">
        <Divider />
      </div>
      {teamId !== 'new' && checkRole('platform_support') && (
        <div className="pb-8">
          {getValues('date_created') && (
            <div>
              <Typography variant="caption" className="font-medium">
                {t('CREATION_DATE')}:
              </Typography>
              <Typography variant="caption">
                {format(parseISO(getValues('date_created')), 'dd/MM/y hh:mm aaaa ')}
              </Typography>
            </div>
          )}
          {getValues('date_edited') && (
            <div>
              <Typography variant="caption" className="font-medium">
                {t('EDITION_DATE')}:
              </Typography>
              <Typography variant="caption">
                {format(parseISO(getValues('date_edited')), 'dd/MM/y hh:mm aaaa ')}
              </Typography>
            </div>
          )}
          <div>
            <Typography variant="caption" className="font-medium">
              ID:
            </Typography>
            <Typography variant="caption">{getValues('id')}</Typography>
          </div>
        </div>
      )}
    </div>
  );
}

export default InformationTab;
