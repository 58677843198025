/* eslint-disable camelcase */
import Box from '@mui/system/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FuseLoading from '@fuse/core/FuseLoading';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Typography from '@mui/material/Typography';
import { getCountriesAll } from 'src/app/store/selectsData/countrySlice';
import { permissionChecker } from 'app/store/userWorkspacePermissionsSlice';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getUserPermissions, getUserRoles, selectUserById } from '../../store/usersSlice';
import { getRoles } from '../../../../../store/selectsData/rolesSlice';

/**
 * the user view content
 * @returns {JSX.Element}
 */
const UserViewPermissions = () => {
  /**
   * @constant {object} {wsId, userId} - the workspace id and the user id
   * @constant {function} {editMode} - the edit mode state
   * @constant {function} {isLoading, setIsLoading} - the loading state and the function to set the loading state
   * @constant {function} checkPermission - the function to check the permissions
   * @constant {function} checkRole - the function to check the roles
   * @constant {object} country - the country object
   * @constant {function} dispatch - the dispatch function from the redux store
   * @constant {array} roles - the roles array
   * @constant {object} user - the user object
   * @constant {object} userItem - the user item object
   * @constant {boolean} isRolesLoaded - if the roles are loaded
   * @constant {function} t - the translation function
   */
  const { wsId, userId } = useParams();
  const userItem = useSelector((state) => selectUserById(state, userId));
  const [isLoading, setIsLoading] = useState(true);
  const checkPermission = useSelector(permissionChecker);
  const dispatch = useDispatch();
  const { isRolesLoaded } = useSelector(({ selectsData }) => selectsData.roles);
  const { t } = useTranslation('managementApp');

  /**
   * divide the permissions in groups
   */
  const sortPermissionGroup = [
    { key: 'client', title: 'CLIENTS', items: [], iconName: 'material-solid:person' },
    { key: 'assignment', title: 'ASSIGNMENTS', items: [], iconName: 'material-solid:assignment' },
    { key: 'form', title: 'FORMS', items: [], iconName: 'heroicons-solid:clipboard-list' },
    { key: 'geofence', title: 'GEOFENCES', items: [], iconName: 'material-solid:travel_explore' },
    { key: 'alert', title: 'ALERTS', items: [], iconName: 'feather:alert-triangle' },
    { key: 'dashboard', title: 'DASHBOARD', items: [], iconName: 'material-solid:dashboard' },
    { key: 'project', title: 'PROJECTS', items: [], iconName: 'heroicons-solid:light-bulb' },
    { key: 'user', title: 'USERS', items: [], iconName: 'material-solid:account_circle' },
    { key: 'team', title: 'TEAMS', items: [], iconName: 'material-solid:groups' },
    { key: 'access_key', title: 'ACCESS_KEYS', items: [], iconName: 'material-solid:vpn_key' },
    { key: 'others', title: 'OTHERS', items: [], iconName: 'material-solid:more_horiz' },
  ];

  const permissionsFiltered = useMemo(() => {
    const permissionsSorted = sortPermissionGroup;
    const permissionsUnsorted = userItem?.permissions || [];

    permissionsUnsorted.forEach((p) => {
      const index = permissionsSorted.findIndex(({ key }) => p.match(key));
      if (index !== -1) {
        permissionsSorted[index].items.push(p);
      } else {
        permissionsSorted[permissionsSorted.length - 1].items.push(p);
      }
    });

    return permissionsSorted;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userItem?.permissions]);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      // reset({ ...userItem });
      if (!isRolesLoaded && checkPermission('superUser')) {
        await dispatch(getRoles({ wsId }));
      }

      await dispatch(getCountriesAll());

      if (userItem) {
        if (!userItem?.roles) {
          await dispatch(getUserRoles({ wsId, userId }));
        }

        if (!userItem?.permissions) {
          await dispatch(getUserPermissions({ wsId, userId }));
        }
      }

      setIsLoading(false);
    };
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, userItem]);

  if (!userItem || isLoading || !userItem.roles) {
    return (
      <div className="file-details flex flex-col flex-auto  h-fullVH w-fullVW sm:h-full sm:w-full">
        <FuseLoading />
      </div>
    );
  }

  return (
    <div className="file-details flex flex-col flex-auto justify-around h-fullVH w-fullVW sm:h-full sm:w-full ">
      <Box className="flex items-center h-1/6 px-10">
        <Typography className=" font-bold flex-1" variant="subtitle2">
          {t('USER_WITH_THIS_ROLES', userItem)}
        </Typography>
      </Box>
      <Divider className=" px-8">
        <Typography
          variant="subtitle1"
          className=" font-semibold tracking-tight leading-8 text-grey-400"
        >
          {t('ROLES')}
        </Typography>
      </Divider>
      <List
        className="w-full overflow-hidden overflow-y-scroll pb-64"
        sx={{
          bgcolor: 'background.paper',
          height: '90%',
        }}
      >
        {userItem.roles.map((role) => {
          return (
            <ListItem key={role.id} disablePadding>
              <ListItemButton role={undefined} dense>
                <ListItemIcon>
                  <FuseSvgIcon className="text-48" size={24} color="action">
                    heroicons-solid:lock-closed
                  </FuseSvgIcon>
                </ListItemIcon>

                <ListItemText id={role.id} primary={role.name} secondary={role.description} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>

      {permissionsFiltered.map(({ key, title, items, iconName }) => {
        if (items.length < 1) return <div key={key} />;
        return (
          <div key={key}>
            <div className="flex items-center justify-center">
              <Divider className="flex-1 px-8">
                <Typography
                  variant="caption"
                  className=" font-semibold tracking-tight leading-8 text-grey-500"
                >
                  {t(title)}
                </Typography>
              </Divider>
              <FuseSvgIcon className="text-48 " size={20} color="action">
                {iconName}
              </FuseSvgIcon>
            </div>
            {items.map((name) => {
              return (
                <ListItem key={name} disablePadding>
                  <ListItemButton role={undefined} dense>
                    <ListItemIcon>
                      <FuseSvgIcon className="text-48" size={24} color="action">
                        heroicons-solid:key
                      </FuseSvgIcon>
                    </ListItemIcon>
                    <ListItemText id={name} primary={t(name.toUpperCase())} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default UserViewPermissions;
